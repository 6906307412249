import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { success, error } from "../../constants/msg";
import Loader from './../loader/Loader';
import { roles } from "../../utils/constants";
import Assets from '../../constants/images';

import { GetCurrentUser } from "./../../services/auth/auth.services";
import { SetTokenLocalStorage, SetAuthUserLocalStorage } from "../../services/auth/auth.services";

import { GENERAL_ERROR_MESSAGES } from '../../constants/app-constants';
import { UserDataContext } from "../../App";

const RememberSessionForm = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [showName, setShowName] = useState(false);
    const { id } = useParams();

    const [userData, setUserData] = useContext(UserDataContext);

    useEffect(() => {
        const getResourceClientAndBoxes = async () => {
            try {
                const res = await GetCurrentUser()
                // console.log(res);

                setIsLoading(false);

                if (res.data.data.role_id === roles.ROLE_PROFESSIONAL) {
                    error(GENERAL_ERROR_MESSAGES.PROFESSIONAL_LOGIN)
                } else {
                    success(res.data.message)
                    if (res.data.data.role_id === roles.ROLE_USER) {
                        setShowName(res.data.data.name);
                        setUserData(res.data.data);
                        SetAuthUserLocalStorage(res.data.data);
                        setTimeout(() => {
                            navigate(`/current-session`)
                        }, 2500)
                    } else {
                        if (res.data.data.verified === 1) {
                            setTimeout(() => {
                                if (res.data.data.current_subscription) {
                                    if (res.data.data.current_subscription.package_id === 3 && res.data.data.user_2fa === false) {
                                        navigate(`/verification`, { state: { user: res.data.data, navigateLink: "sub-admin/manage-group", isResetPassword: false, TwoFa: true } })
                                    } else {
                                        setUserData(res.data.data);
                                        SetAuthUserLocalStorage(res.data.data);
                                        navigate(`/sub-admin/manage-group`)
                                    }
                                } else {
                                    setUserData(res.data.data);
                                    SetAuthUserLocalStorage(res.data.data);
                                    navigate(`/sub-admin/subscription`)
                                }
                            }, 2500)
                        } else {
                            if (res.data.data.current_subscription) {
                                setTimeout(() => {
                                    navigate(`/verification`, { state: { user: res.data.data, navigateLink: "sub-admin/manage-group", isResetPassword: false } })
                                }, 2500)
                            } else {
                                setTimeout(() => {
                                    navigate(`/verification`, { state: { user: res.data.data, navigateLink: "sub-admin/subscription", isResetPassword: false } })
                                }, 2500)
                            }
                        }
                    }
                }
            } catch (e) {
                error(e.response.data.message)
                setIsLoading(false);

                navigate(`/signin`);
            }
        }

        SetTokenLocalStorage(id);
        setTimeout(() => {
            getResourceClientAndBoxes();
        }, 1500);
    }, [id]);

    return (
        <>
            {
                isLoading ?
                    <div className="mt-4 text-center">
                        <Loader />
                    </div>
                    :
                    <div className='forms forgot-password-form mb-2 p-5 py-xl-4 py-xxl-5'>

                        <div className='text-center border-bottom pb-4'>
                            <img id="logo" className='img-fluid' src={Assets.Logo} alt="Logo" />
                        </div>

                        <div>
                            <div className='text-center mt-4 mb-5'>
                                <h5 className='p-0 m-0'>Welcome {showName}</h5>
                                <p className='mt-3'>

                                </p>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default RememberSessionForm;
