import React from 'react'
import { Col } from 'react-bootstrap'
import ChangePasswordForm from '../components/authentication/ChangePasswordForm'

const SubAdminChangePasswordPage = () => {
    return (
        <Col xs={12} sm={12} md={12} lg={{ span: 9, offset: 2 }} xl={{ offset: 2, span: 6 }} xxl={{ offset: 2, span: 6 }} >
            <ChangePasswordForm navigateLink="sub-admin/manage-group" />
        </Col>
    )
}

export default SubAdminChangePasswordPage