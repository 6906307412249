import React, { useState } from 'react'
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form";
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import OtpInput from "react-otp-input";
import { success, error } from "../../constants/msg";

import Assets from '../../constants/images';
import { ForgetPasswordRequest } from "../../services/auth/auth.services"

const ForgotPasswordForm = () => {
    const navigate = useNavigate();
    const [isDisabled, setIsDisabled] = useState(false);

    const { register, handleSubmit, formState: { errors, isValid } } = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        const { email } = data
        const user = {
            email: email
        }
        setIsDisabled(true)

        try {
            const res = await ForgetPasswordRequest(email);
            success(res.data.message)
            setTimeout(() => {
                navigate("/verification", { state: { user: user, navigateLink: "reset-password", isResetPassword: true } })
                setIsDisabled(false)
            }, 1500)
        }
        catch (e) {
            error(e.response.data.message)
            setTimeout(async () => {
                setIsDisabled(false)
            }, 1500)
        }
    }

    return (
        <div className='forms forgot-password-form p-5 py-xl-4 py-xxl-5 mb-2'>

            <div className='text-center border-bottom pb-4'>
                <img id="logo" className='img-fluid' src={Assets.Logo} alt="Logo" />
            </div>

            <div>
                <div className='text-center mt-4 mb-5'>
                    <h5 className='p-0 m-0'>Forgot Password</h5>
                    <p className='mt-3'>
                        Enter the email associated with your
                        account and
                        we'll send verification code
                        to reset your password
                    </p>
                </div>

                <div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='mt-4'>
                            <Form.Label>E-Mail Address  *</Form.Label>
                            <InputGroup className=''>
                                <Form.Control
                                    name="email"
                                    type="email"
                                    placeholder="Enter email address"
                                    maxLength={VALIDATIONS.EMAIL}
                                    {...register("email",
                                        {
                                            maxLength: {
                                                value: VALIDATIONS.EMAIL,
                                                message: VALIDATIONS_TEXT.EMAIL_MAX
                                            },
                                            required: {
                                                value: true,
                                                message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                            },
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                            }
                                        })
                                    }
                                />
                            </InputGroup>
                            {errors.email && <small className='text-red'>{errors.email.message}</small>}
                        </div>

                        <div className='mt-5'>
                            <Button disabled={isDisabled} type="submit" className="w-100">Submit</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordForm

