import { toast } from "react-toastify";

export const success = (msg) => toast.success(msg, {
    autoClose: 3000,
    hideProgressBar: true
});

export const error = (msg) => toast.error(msg, {
    autoClose: 3000,
    hideProgressBar: true
});

export const errorMsg = (msg) => toast.error(msg, {
    autoClose: 3000,
    hideProgressBar: true
});