import React, { useState, useContext, useEffect } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import { AddBoxPopupContext } from "../../App"
import { useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";

import { CalendarPopupContext } from '../../App';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const CalendarPopup = ({ handleFilter, setIsFiltered }) => {
    const [value, onChange] = useState(new Date());
    const [handleCalendarPopup, setHandleCalendarPopup] = useContext(CalendarPopupContext);
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const returnConvertDateFormat = () => {
        let localDate = new Date(value).toLocaleDateString();
        let date = localDate.split("/")
        if (date[0] < 10) {
            date[0] = "0" + date[0]
        }
        if (date[1] < 10) {
            date[1] = "0" + date[1]
        }
        date = date[2] + "-" + date[0] + "-" + date[1]
        return date
    }

    const onSubmit = (data) => {
        handleFilter(returnConvertDateFormat())
        setHandleCalendarPopup(false)
        setIsFiltered(true)
    }

    return (
        <div>

            <Modal
                className='calendar-modal'
                show={handleCalendarPopup}
                onHide={() => setHandleCalendarPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setHandleCalendarPopup(false)} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center py-4'>
                            <span className='p-0 m-0 '>Filter By Date</span>
                        </div>

                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='px-4 pb-5'>
                                <div>
                                    <Calendar className="w-100" onChange={onChange} value={value} calendarType="US" />
                                </div>

                                <div className='mt-5'>
                                    <Button type='submit' className="btn-filter w-100">Filter</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CalendarPopup