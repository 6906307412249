export const roles = {
    ROLE_PROFESSIONAL: 3,
    ROLE_SUB_ADMIN: 4,
    ROLE_USER: 5,
}

export const constant = {
    DEVICE_TYPE: "web",
    DEVICE_TOKEN: '1234',
    LOCAL_STORAGE_TOKEN: 'AUTH_ACCESS_TOKEN',
    LOCAL_STORAGE_USER: 'USER_DATA',
    LOCAL_DEVICE_TOKEN: 'LOCAL_DEVICE_TOKEN',
    BASE_URL: process.env.REACT_APP_BASE_URL,
    resourceDirName: "resources",
    imageDirName: "users"
}

export const apiUrl = {
    register: "register",
    login: 'login',
    logout: 'logout',
    forget_password: 'forget-password',
    verify_otp: 'verify-email-code',
    reset_password: 'reset-password',
    change_password: 'change-password',
    change_password_later: 'change-password-later',
    terms_and_conditions: 'terms-and-conditions',
    pages: 'pages',
    e_sessions: 'e-sessions',
    verify_reset_code: 'verify-reset-code',
    resend_code: 'resend-code',
    boxes: 'boxes',
    current_session: 'current_session',
    notifications: 'notifications',
    groups: 'groups',
    packages: 'packages',
    e_resources: 'e-resources',
    resource_boxes: 'resource-boxes',
    users: 'users',
    email_templates: "email-templates",
    resource_clients: "resource-clients",
    me: "me",
    import_client: "import-client",
    ADD_USER_CARD: "add-stripe-card",
    get_user_cards: "get-stripe-card",
    delete_user_card: "delete-stripe-card",
    user_subscriptions: "user-subscriptions",
    user_subscriptions_cancel: "user-subscriptions-cancel",
}

export const s3Credential = {
    bucketName: "grahmity-app",
    region: "us-east-1",
    dirName: "",
    accessKeyId: "AKIAVHYR4SEOJF3F7RDR",
    secretAccessKey: "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",
    s3EndPoint: "https://grahmity-app.s3.us-east-1.amazonaws.com/",
    fileSize: "10000000000"
}

export const stripeCredentials = {
    STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
    STRIPE_SECRET: process.env.REACT_APP_STRIPE_SECRET,
    // STRIPE_KEY: "pk_test_2kwes8cuUU2RbXg6xmnsrnqs00j4rEjAIa",
    // STRIPE_SECRET: "sk_test_qMnqPmamMdfhPd4918R71lKY00wLy6XYoD",
}

export const errorMessages = {
    fileSize: "The file size is too large",
    fileSuccess: "file uploaded successfully",
    fileError: "Error in uploading file",
}
