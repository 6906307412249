import React, { useState, useEffect, useContext } from 'react'
import { Route, Navigate, Outlet, useNavigate } from "react-router";
import { GetAuthUserLocalStorage, GetTokenLocalStorage } from "./services/auth/auth.services"
import { roles } from './utils/constants';

const PublicRoute = () => {
    const token = GetTokenLocalStorage()
    const user = GetAuthUserLocalStorage()

    return (
        (token && user) ? <Navigate to={`${user.role_id === roles.ROLE_USER ? "/current-session" : "/sub-admin/manage-group"}`} /> : <Outlet />
    )
}

export default PublicRoute