import React, { useContext, useEffect, useState } from 'react'
import { Container, Button, Col, Row, Form } from 'react-bootstrap';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import PaymentCheckCard from '../components/home/PaymentCheckCard';
import SubscriptionPlanCard from '../components/home/SubscriptionPlanCard';
import Assets from '../constants/images';
import { StripeIcon } from '../constants/svgs';
import { StripeCardPopupContext, UserDataContext } from '../App';
import StripeCardPopup from '../components/popup/StripeCardPopup';
import { Controller, useForm } from "react-hook-form";
import { error, success } from '../constants/msg';
import {
    GetAuthUserLocalStorage,
    GetCurrentUser,
    PayUserSubscription,
    SetAuthUserLocalStorage,
    GetStripeCard
} from '../services/auth/auth.services';
import { FaCcVisa, FaTimesCircle, FaCreditCard } from "react-icons/fa"
import DeletePaymentCardPopup from '../components/popup/DeletePaymentCardPopup';

const PaymentMethodPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [data, setData] = useState(location.state.data)
    const [paymentType, setPaymentType] = useState("")
    const [addCardModalShow, setAddCardModalShow] = useState("")
    const [handleStripeCardPopup, setHandleStripeCardPopup] = useContext(StripeCardPopupContext)
    const [isCardAdded, setIsCardAdded] = useState(false)
    const [checked, setChecked] = useState(false)
    const [userData, setUserData] = useState(UserDataContext);
    const [prevRoute, setPrevRoute] = useState(location.state.navigateLink)
    const [deleteCardPopup, setDeleteCardPopup] = useState(false)
    const [userCards, setUserCards] = useState(null)
    const [userDeleteCardId, setUserDeleteCardId] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false);
    const [userCurrentSubscription, setUserCurrentSubscription] = useState(true)

    useEffect(() => {
        const user = GetAuthUserLocalStorage()
        setUserCurrentSubscription(user.current_subscription)
        // console.log(user.current_subscription)
    }, [])


    const { register, control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        if (data.id == "1") {
            setPaymentType("1")
        }
        getUserSavedCards()
    }, [])


    useEffect(() => {
        if (userCards !== null && userCards.length > 0) {
            // console.log('userCards', userCards)
            setIsCardAdded(true)
        } else {
            setIsCardAdded(false)
        }
    }, [userCards])

    const getUserSavedCards = async () => {
        try {
            await GetStripeCard().then(async (res) => {
                if (res.data.success) {
                    setUserCards(res.data.data)
                } else {
                    setUserCards(null)
                    // console.log(res.data.message)
                }
            })
        } catch (e) {
            console.log(e.response.data.message)
        }
    }

    const onSubmit = async () => {
        if (paymentType == "") {
            error("Please select package type")
        } else if (!isCardAdded) {
            error("Please add stripe card")
        }
        else {
            setIsDisabled(true)
            try {
                const package_id = data.id
                await PayUserSubscription(package_id, paymentType).then(async (res) => {
                    try {
                        const response = await GetCurrentUser()
                        setUserData(response.data.data)
                        SetAuthUserLocalStorage(response.data.data)
                        success(res.data.message)
                        setTimeout(() => {
                            setIsDisabled(false)
                            navigate("/sub-admin/manage-group")
                        }, 1500)
                    } catch (e) {
                        error(e.response.data.message)
                        setTimeout(async () => {
                            setIsDisabled(false)
                        }, 1500)
                    }
                })
            } catch (e) {
                error(e.response.data.message)
                setTimeout(async () => {
                    setIsDisabled(false)
                }, 1500)
            }
        }
    }

    const deleteUserSavedCards = async (id) => {
        setUserDeleteCardId(id)
        setDeleteCardPopup(true)
    }

    return (
        <Container>
            <StripeCardPopup setIsCardAdded={setIsCardAdded} setUserCards={setUserCards} />
            <DeletePaymentCardPopup deleteCardPopup={deleteCardPopup} setDeleteCardPopup={setDeleteCardPopup} id={userDeleteCardId} setUserCards={setUserCards} />
            <div className='payment-method-page bg-white p-4'>
                <div className='text-center border-bottom pb-4'>
                    <img className='img-fluid' src={Assets.Logo} alt="Logo" />
                </div>

                <div className='text-center mt-5 mb-4'>
                    <h5>Payment Method</h5>
                    <p className='mt-3'>Select your payment method</p>
                </div>

                <div className='mt-5'>
                    <Row>
                        <Col sm={6} md={9} lg={6} xl={{ span: 4, offset: 1 }} xxl={4} className="mb-4">
                            <SubscriptionPlanCard showBtn={false} data={data} permissions={JSON.parse(data.data)} />
                            <NavLink className="d-inline-block mt-4" to={`${prevRoute ? "/sub-admin/settings/subscription-upgrade" : "/sub-admin/subscription"}`}>Change Plan</NavLink>
                        </Col>

                        <Col sm={6} md={12} lg={6} xl={{ span: 5, offset: 1 }} xxl={{ span: 5, offset: 1 }}
                            className="mb-4">
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    {
                                        (data?.id == "2" || data?.id == "3") &&
                                        <>
                                            <Col xs={12} className="mb-4">
                                                <PaymentCheckCard paymentType={paymentType} id="1" type="Monthly"
                                                    price={data.currency === 'USD' ? '$ ' + data.monthly_price : data.currency + ' ' + data.monthly_price} name="same"
                                                    setPaymentType={setPaymentType} showIcon={false} curType={userCurrentSubscription ? userCurrentSubscription?.type : ''} curPack={userCurrentSubscription ? userCurrentSubscription?.package?.id : ''} packId={data.id} />
                                            </Col>

                                            <Col xs={12} className="mb-4">
                                                <PaymentCheckCard paymentType={paymentType} id="2" type="Yearly"
                                                    price={data.currency === 'USD' ? '$ ' + data.yearly_price : data.currency + ' ' + data.yearly_price} name="same"
                                                    setPaymentType={setPaymentType} showIcon={false} curType={userCurrentSubscription ? userCurrentSubscription?.type : ''} curPack={userCurrentSubscription ? userCurrentSubscription?.package?.id : ''} packId={data.id} />
                                            </Col>
                                        </>
                                    }

                                    <Col xs={12}
                                        className={`my-5 ${(data?.is_free == 0) ? "mt-5" : "mt-0"} `}>
                                        <Form.Label className="w-100">
                                            <div className="payment-check-card p-3 pe-4">
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className='d-flex w-100 align-items-center'>
                                                        <StripeIcon />
                                                        <h6 className='p-0 m-0 ms-3 me-3'>Stripe Account</h6>
                                                    </div>

                                                    <div className='checkbox-container'>
                                                        <Form.Check onClick={() => {
                                                            setChecked(true)
                                                            setHandleStripeCardPopup(true)
                                                        }}
                                                            checked={checked}
                                                            readOnly
                                                            type="radio"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Label>
                                    </Col>

                                    <Col xs={12} className="mb-3">
                                        <div className="user-card">
                                            <ul>
                                                {
                                                    userCards && userCards.map((data, i) => (
                                                        <li key={i}>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div className="d-flex align-items-center">
                                                                    <FaCreditCard className="visa-icon" />
                                                                    <span className="d-block ms-3 text-card">
                                                                        **** {data?.last_four} ({data?.brand.toUpperCase()})
                                                                        <span className="expiry">Expiry: {data?.exp_month}/{data?.exp_year}</span>
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span onClick={() => deleteUserSavedCards(data?.id)} className="time-icon"><FaTimesCircle /></span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </Col>

                                    <Col xs={12} className="mb-4">
                                        {data.is_free == 0 ?
                                            <Button disabled={isDisabled} type="submit" className="w-100">Pay Now</Button>
                                            :
                                            <Button disabled={isDisabled} type="submit" className="w-100">Continue</Button>
                                        }
                                    </Col>
                                </Row>
                            </Form>
                        </Col>

                        <Col sm={1}></Col>
                    </Row>
                </div>
            </div>
        </Container>
    )
}

export default PaymentMethodPage