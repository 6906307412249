import React, { useContext, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import { NavLink } from 'react-router-dom'
import { ProfilePopupContext, DeleteAccountPopupContext, LogoutPopupContext, UserDataContext } from "../../App"
import Assets from '../../constants/images'
import { LockIcon, LogoutIcon, PrivacyIcon, SingleUserIcon, TermsAndConditionIcon, UserIcon } from '../../constants/svgs'
import DeleteAccountPopup from './DeleteAccountPopup'
import LogoutPopup from './LogoutPopup'

const ProfilePopup = () => {
    const [handleProfilePopup, setHandleProfilePopup] = useContext(ProfilePopupContext);
    const [handleDeleteAccountPopup, setHandleDeleteAccountPopup] = useContext(DeleteAccountPopupContext);
    const [handleLogoutPopup, setHandleLogoutPopup] = useContext(LogoutPopupContext);
    const [userData, setUserData] = useContext(UserDataContext);

    return (
        <div>
            <DeleteAccountPopup />
            <LogoutPopup btnText1="Cancel" btnText2="Log Out" heading="Log Out" title=" Are you sure you would like to Log Out of your Grahamity account ?" />

            <Modal
                className='profile-modal'
                show={handleProfilePopup}
                onHide={() => setHandleProfilePopup(false)}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"

            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => setHandleProfilePopup(false)} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='profile-container p-2'>
                        <div className='text-center border-bottom pb-4 mb-4'>
                            <h4 className="p-0 m-0">My Account</h4>
                        </div>

                        <div className='img-container text-center border-bottom pb-3 '>
                            <div className="img-holder mx-auto text-center">
                                <img onError={(e)=> e.target.src = Assets.ProfilePlaceHolder} src={userData?.details?.image_url ? userData.details.image_url : Assets.AvatarLarge} alt="Avatar" />
                            </div>
                            <h6 className='mt-3 text-break'>{userData?.name}</h6>
                        </div>

                        <div className='link-container'>
                            <ul>
                                <li className='border-bottom py-3'>
                                    <div className='d-flex align-items-center'>
                                        <span><SingleUserIcon /></span>
                                        <NavLink className="ms-2" to="/profile">Profile</NavLink>
                                    </div>
                                </li>

                                <li className='border-bottom py-3'>
                                    <div className='d-flex align-items-center'>
                                        <span><LockIcon /></span>
                                        <NavLink className="ms-2" to="/change-password">Change Password</NavLink>
                                    </div>
                                </li>

                                {/* <li className='border-bottom py-3'>
                                    <div className='d-flex align-items-center'>
                                        <span><UserIcon /></span>
                                        <NavLink className="ms-2" to="#" onClick={()=>setHandleDeleteAccountPopup(true)}>Delete Account</NavLink>
                                    </div>
                                </li> */}

                                <li className='border-bottom py-3'>
                                    <div className='d-flex align-items-center'>
                                        <span><TermsAndConditionIcon /></span>
                                        {/* <NavLink className="ms-2" to="/terms-and-conditions">Terms and Conditions</NavLink> */}
                                        <a className="ms-2" target="_blank" href="https://www.grahamity.com/terms">Terms and Conditions</a>
                                    </div>
                                </li>

                                <li className='border-bottom py-3'>
                                    <div className='d-flex align-items-center'>
                                        <span><PrivacyIcon /></span>
                                        {/* <NavLink className="ms-2" to="/privacy-policy">Privacy Policy</NavLink> */}
                                        <a className="ms-2" target="_blank" href="https://www.grahamity.com/privacy/">Privacy Policy</a>
                                    </div>
                                </li>

                                <li className='border-bottom py-3'>
                                    <div className='d-flex align-items-center'>
                                        <span><LogoutIcon /></span>
                                        <NavLink className="ms-2" to="#" onClick={() => setHandleLogoutPopup(true)}>Logout</NavLink>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ProfilePopup