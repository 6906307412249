import axios from "axios";
import { constant, apiUrl, roles } from "../../utils/constants"
import { v4 as uuid } from 'uuid';

const storageToken = constant.LOCAL_STORAGE_TOKEN;
const storageUser = constant.LOCAL_STORAGE_USER;
const storageDeviceToken = constant.LOCAL_DEVICE_TOKEN

export const SetTokenLocalStorage = (token) => {
    localStorage.setItem(storageToken, token)
}

export const SetAuthUserLocalStorage = (user) => {
    localStorage.setItem(storageUser, JSON.stringify(user))
}

export const StorageDeviceToken = () => {
    if (localStorage.getItem(storageDeviceToken) && localStorage.getItem(storageDeviceToken) !== null && localStorage.getItem(storageDeviceToken) !== '') {
        return localStorage.getItem(storageDeviceToken)
    } else {
        const uniqueToken = uuid();
        const deviceToken = {
            'device_type': 'web',
            'device_token': uniqueToken,
        }
        localStorage.setItem(storageDeviceToken, JSON.stringify(deviceToken))
        return localStorage.getItem(storageDeviceToken)
    }
}

export const GetTokenLocalStorage = () => {
    if (localStorage.getItem(storageToken)) {
        return localStorage.getItem(storageToken)
    } else {
        return null;
    }
}

export const GetAuthUserLocalStorage = () => {
    if (localStorage.getItem(storageUser)) {
        return JSON.parse(localStorage.getItem(storageUser))
    } else {
        return null;
    }
}

export const GetStorageDeviceToken = () => {
    if (localStorage.getItem(storageDeviceToken)) {
        return localStorage.getItem(storageDeviceToken)
    } else {
        return null;
    }
}

export const EmptyLocalStorage = () => {
    localStorage.removeItem(storageUser);
    localStorage.removeItem(storageToken)
}

export const Signup = async (company_name, name, email, password, password_confirmation, role_id, device_type, device_token) => {
    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.register}`, {
        company_name,
        name,
        email,
        password,
        password_confirmation,
        role_id,
        device_type,
        device_token
    })
    return response
}

export const Login = async (email, password, device_type, device_token) => {
    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.login}`, {
        email,
        password,
        device_type,
        device_token
    })
    return response
}

export const Logout = async () => {
    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.logout}`, null, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response;
}

export const ChangePassword = async (current_password, password, password_confirmation) => {
    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.change_password}?current_password=${current_password}&password=${password}&password_confirmation=${password_confirmation}`, null, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response;
}

export const ChangePasswordRemindLater = async (remindMeNever = false) => {
    if (remindMeNever === true) {
        const response = await axios.post(`${constant.BASE_URL}/${apiUrl.change_password_later}?remind_me_never=${remindMeNever}`, null, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        });
        return response;
    } else {
        const response = await axios.post(`${constant.BASE_URL}/${apiUrl.change_password_later}`, null, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        });
        return response;
    }

}

export const ForgetPasswordRequest = async (email) => {
    const response = await axios.get(`${constant.BASE_URL}/${apiUrl.forget_password}?email=${email}`)
    return response
}

export const ResendVerificationCode = async (email, twoFa = false) => {

    if (twoFa) {
        const response = await axios.post(`${constant.BASE_URL}/${apiUrl.resend_code}?email=${email}&two_fa=${twoFa}`)
        return response
    } else {
        const response = await axios.post(`${constant.BASE_URL}/${apiUrl.resend_code}?email=${email}`)
        return response
    }

}

export const VerifyResetPasswordOtpCode = async (verification_code) => {
    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.verify_reset_code}?verification_code=${verification_code}`)
    return response
}

export const VerifyOtpCode = async (email, verification_code, device_type, device_token) => {
    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.verify_otp}?verification_code=${verification_code}&email=${email}`, {
        device_type,
        device_token
    })
    return response
}

export const ResetPassword = async (email, verification_code, password, password_confirmation) => {
    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.reset_password}?email=${email}&verification_code=${verification_code}&password=${password}&password_confirmation=${password_confirmation}`)
    return response
}

export const GetEssionsListing = async (subadmin_user_id) => {
    const response = await axios.get(`${constant.BASE_URL}/${apiUrl.e_sessions}?orderBy=id&subadmin_user_id=${subadmin_user_id}&${apiUrl.current_session}=1`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    })
    return response;
}

export const GetSpecificEsession = async (session_id) => {
    const response = await axios.get(`${constant.BASE_URL}/${apiUrl.boxes}?session_id=${session_id}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    })
    return response
}

export const GetNotification = async () => {
    const response = await axios.get(`${constant.BASE_URL}/${apiUrl.notifications}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response;
}

export const AddGroup = async (session_id, name, status, position) => {
    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.groups}`, { session_id, name, status, position }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    })
    return response;
}

export const AddBox = async (group_id, name, status, position) => {
    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.boxes}`, { group_id, name, status, position }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    })
    return response;
}

export const GetGroups = async (session_id, sortedBy = null) => {
    if (!sortedBy) {
        const response = await axios.get(`${constant.BASE_URL}/${apiUrl.groups}?orderBy=id&sortedBy=desc&session_id=${session_id}`, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        })
        return response;
    } else {
        const response = await axios.get(`${constant.BASE_URL}/${apiUrl.groups}?orderBy=position&sortedBy=${sortedBy}&session_id=${session_id}`, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        })
        return response;
    }

}

export const GetBoxes = async (session_id, sortedBy = null) => {
    if (!sortedBy) {
        const response = await axios.get(`${constant.BASE_URL}/${apiUrl.boxes}?session_id=${session_id}`, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        })
        return response;
    } else {
        const response = await axios.get(`${constant.BASE_URL}/${apiUrl.boxes}?orderBy=position&sortedBy=${sortedBy}&session_id=${session_id}`, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        })
        return response;
    }

}

export const GetSubscriptionPackages = async (session_id) => {
    const response = await axios.get(`${constant.BASE_URL}/${apiUrl.packages}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    })
    return response;
}

export const DeleteResource = async (id) => {
    const response = await axios.delete(`${constant.BASE_URL}/${apiUrl.resource_boxes}/${id}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response
}

export const RegisterClientByEmail = async (name, email, phone, password, subadmin_user_id) => {
    const device_token = constant.DEVICE_TOKEN
    const device_type = constant.DEVICE_TYPE
    const role_id = roles.ROLE_USER
    const password_confirmation = password

    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.register}`, {
        name,
        email,
        phone,
        password,
        password_confirmation,
        device_token,
        device_type,
        role_id,
        subadmin_user_id
    });
    return response

}

export const GetUsers = async () => {
    const response = await axios.get(`${constant.BASE_URL}/${apiUrl.users}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response
}

export const SaveEmailTemplate = async (subadmin_user_id, html_body) => {
    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.email_templates}`, { subadmin_user_id, html_body }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response
}

export const AddStripeCard = async (payload) => {
    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.ADD_USER_CARD}`, payload, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response
}


export const GetStripeCard = async () => {
    const response = await axios.get(`${constant.BASE_URL}/${apiUrl.get_user_cards}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response
}

export const DeleteStripeCard = async (id) => {
    const response = await axios.get(`${constant.BASE_URL}/${apiUrl.delete_user_card}/${id}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response
}

export const CancelStripeSubscription = async () => {
    const response = await axios.get(`${constant.BASE_URL}/${apiUrl.user_subscriptions_cancel}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response
}

export const GetEmailTemplate = async (subadmin_user_id) => {
    const response = await axios.get(`${constant.BASE_URL}/${apiUrl.email_templates}?subadmin_user_id=${subadmin_user_id}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response;
}

export const GetResourceClient = async (subadmin_user_id, groupBy = false, receiverId = null) => {
    let queryParams = '?orderBy=id&sortedBy=desc&subadmin_user_id=' + subadmin_user_id;
    if (groupBy === true) {
        queryParams = queryParams + '&group_by=created_at';
    }
    if (receiverId !== null) {
        queryParams = queryParams + '&group_by=created_at&reciever_id=' + receiverId;
    }

    const response = await axios.get(`${constant.BASE_URL}/${apiUrl.resource_clients}` + queryParams, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response;

}

export const UploadEresource = async (title, description, url, type, size, subadmin_user_id) => {
    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.e_resources}`, {
        title,
        description,
        url,
        type,
        size,
        subadmin_user_id
    }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response;
}

export const SaveResourceInBoxes = async (resource_id, box_id, group_id) => {
    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.resource_boxes}`, {
        resource_id,
        box_id,
        group_id
    }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response;
}

export const SaveResourceInDeletedGroupBoxes = async (resource_id, box_id) => {
    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.resource_boxes}`, { resource_id, box_id }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response;
}

export const SaveResourceInGroups = async (resource_id, group_id) => {
    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.resource_boxes}`, { resource_id, group_id }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response;
}

export const GetResourceBoxes = async (sortedBy = null) => {
    if (!sortedBy) {
        const response = await axios.get(`${constant.BASE_URL}/${apiUrl.resource_boxes}?orderBy=id&sortedBy=desc&current_session=1`, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        });
        return response;
    } else {
        const response = await axios.get(`${constant.BASE_URL}/${apiUrl.resource_boxes}?orderBy=id&sortedBy=${sortedBy}&current_session=1`, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        });
        return response;
    }

}

export const UpdateUserProfile = async (name, phone, address, image_url, id, isImage) => {
    if (isImage) {
        const response = await axios.put(`${constant.BASE_URL}/${apiUrl.users}/${id}`, {
            name,
            phone,
            address,
            image_url
        }, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        });
        return response;
    } else {
        const response = await axios.put(`${constant.BASE_URL}/${apiUrl.users}/${id}`, { name, phone, address }, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        });
        return response;
    }

}

export const GetCurrentUser = async () => {
    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.me}`, null, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response;
}

export const UploadClientByCsvFile = async (file) => {
    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.import_client}`, file, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response;
}

export const DeleteGroup = async (id) => {
    const response = await axios.delete(`${constant.BASE_URL}/${apiUrl.groups}/${id}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response
}

export const DeleteBox = async (id) => {
    const response = await axios.delete(`${constant.BASE_URL}/${apiUrl.boxes}/${id}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response
}

export const DeleteClientHistory = async (id) => {
    const response = await axios.delete(`${constant.BASE_URL}/${apiUrl.resource_clients}/${id}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response
}

export const EditGroup = async (id, name, position = null) => {
    if (position) {
        const response = await axios.put(`${constant.BASE_URL}/${apiUrl.groups}/${id}`, { position }, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        });
        return response
    } else {
        const response = await axios.put(`${constant.BASE_URL}/${apiUrl.groups}/${id}`, { name }, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        });
        return response
    }
}

export const EditBox = async (id, name, position = null) => {
    if (position) {
        const response = await axios.put(`${constant.BASE_URL}/${apiUrl.boxes}/${id}`, { position }, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        });
        return response
    } else {
        const response = await axios.put(`${constant.BASE_URL}/${apiUrl.boxes}/${id}`, { name }, {
            'headers': {
                'Authorization': 'Bearer ' + GetTokenLocalStorage()
            }
        });
        return response
    }
}

export const GetUsersAddedBySubadmin = async (subadmin_user_id, role) => {
    const response = await axios.get(`${constant.BASE_URL}/${apiUrl.users}?orderBy=id&sortedBy=desc&subadmin_user_id=${subadmin_user_id}&role=${role}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response
}

export const GetDeletedGroupBoxes = async (session_id) => {
    const response = await axios.get(`${constant.BASE_URL}/${apiUrl.boxes}?orderBy=id&sortedBy=desc&session_id=${session_id}&deleted_group=1`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response
}


export const PayUserSubscription = async (package_id, type) => {
    const platform = 3
    const currency = "USD"
    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.user_subscriptions}`, {
        platform,
        currency,
        package_id,
        type
    }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response;
}


export const CreateSession = async (subadmin_user_id, name) => {
    const status = 1
    const response = await axios.post(`${constant.BASE_URL}/${apiUrl.e_sessions}`, { subadmin_user_id, name, status }, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    });
    return response;
}