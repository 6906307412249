import React, { useContext, useState, useEffect } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"
import { AddByEmailPopupContext, AddClientPopupContext, UserDataContext } from "../../App"
import { Controller, useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";

import { useNavigate } from "react-router-dom";
import { RegisterClientByEmail } from '../../services/auth/auth.services';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const AddByEmailPopup = ({ count, setCount }) => {
    const [handleAddClientPopup, setHandleAddClientPopup] = useContext(AddClientPopupContext)
    const [handleAddByEmailPopup, setHandleAddByEmailPopup] = useContext(AddByEmailPopupContext)
    const [isDisabled, setIsDisabled] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    const [userData, setUserData] = useContext(UserDataContext);

    const handleClosePopup = () => {
        setHandleAddByEmailPopup(false)
        setHandleAddClientPopup(true)
    }

    const navigate = useNavigate();
    const { register, control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        let { name, email, contact, password } = data
        setIsDisabled(true)

        try {
            const res = await RegisterClientByEmail(name, email, contact, password, userData.id)
            success("Client added successfully")
            setHandleAddByEmailPopup(false)
            setHandleAddClientPopup(false)
            reset({
                name: "", email: "", contact: "", password: "Welcome@123"
            })
            setCount(count + 1)
            setIsDisabled(false)
        }
        catch (e) {
            error(e.response.data.message)
            setTimeout(async () => {
                setIsDisabled(false)
            }, 1500)
        }
    }

    useEffect(() => {
        reset({
            password: "Welcome@123"
        })
    }, [])

    return (
        <div>
            <Modal
                className='add-group-modal'
                show={handleAddByEmailPopup}
                onHide={() => {
                    handleClosePopup()
                    reset({
                        name: "", email: "", contact: "", password: "Welcome@123"
                    })
                }}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="header">
                        <div onClick={() => {
                            handleClosePopup()
                            reset({
                                name: "", email: "", contact: "", password: "Welcome@123"
                            })
                        }} className="fa-times"><FaTimes /></div>
                    </div>

                    <div className='content-container'>
                        <div className='text-center border-bottom py-4'>
                            <h6 className='p-0 m-0 '>Add Client</h6>
                        </div>

                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='p-5'>
                                <div className='text-center mb-5'>
                                    <p>
                                        Please enter client email address to add a client
                                    </p>
                                </div>

                                <div className=''>
                                    <Form.Label>Client Name</Form.Label>
                                    <InputGroup className=''>
                                        <Form.Control
                                            onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || e.key === "0" && e.preventDefault() || Number(e.key)) && e.preventDefault()}
                                            name="name"
                                            type="text"
                                            placeholder="Enter name"
                                            autoFocus={true}
                                            maxLength={VALIDATIONS.NAME}
                                            {...register("name",
                                                {
                                                    maxLength: {
                                                        value: VALIDATIONS.NAME,
                                                        message: VALIDATIONS_TEXT.NAME_MAX
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.NAME_REQUIRED
                                                    },
                                                })
                                            }
                                        />
                                    </InputGroup>
                                    {errors.name && <small className='text-red'>{errors.name.message}</small>}
                                </div>

                                <div className='mt-3'>
                                    <Form.Label>Client Email</Form.Label>
                                    <InputGroup className=''>
                                        <Form.Control
                                            name="email"
                                            type="email"
                                            placeholder="Enter email address"
                                            maxLength={VALIDATIONS.EMAIL}
                                            {...register("email",
                                                {
                                                    maxLength: {
                                                        value: VALIDATIONS.EMAIL,
                                                        message: VALIDATIONS_TEXT.EMAIL_MAX
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                                    },
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                    }
                                                })
                                            }
                                        />
                                    </InputGroup>
                                    {errors.email && <small className='text-red'>{errors.email.message}</small>}
                                </div>

                                <div className='mt-3'>
                                    <Form.Label>Contact Number</Form.Label>
                                    <InputGroup className=''>
                                        <Form.Control
                                            onKeyDown={(e) => (e.key === "." || e.key === "e" || e.key === "E") && e.preventDefault()}
                                            name="contact"
                                            type="number"
                                            placeholder="Enter contact number"
                                            maxLength={VALIDATIONS.PHONE_MAX}
                                            {...register("contact",
                                                {
                                                    required: {
                                                        value: false,
                                                        message: VALIDATIONS_TEXT.PHONE_REQUIRED
                                                    },
                                                    minLength: {
                                                        value: VALIDATIONS.PHONE_MIN,
                                                        message: VALIDATIONS_TEXT.PHONE_MIN
                                                    },
                                                    maxLength: {
                                                        value: VALIDATIONS.PHONE_MAX,
                                                        message: VALIDATIONS_TEXT.PHONE_MAX
                                                    },
                                                    pattern: {
                                                        value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                                                        message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                                    },
                                                })
                                            }
                                        />
                                    </InputGroup>
                                    {errors.contact && <small className='text-red'>{errors.contact.message}</small>}
                                </div>

                                <div className='mt-3'>
                                    <Form.Label>Password</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            disabled
                                            name="password"
                                            type={!showPassword ? "password" : "text"}
                                            className="password-input"
                                            placeholder="Enter password"
                                            maxLength={VALIDATIONS.PASSWORD_MAX}
                                            {...register("password",
                                                {
                                                    minLength: {
                                                        value: VALIDATIONS.PASSWORD_MIN,
                                                        message: VALIDATIONS_TEXT.PASSWORD_MIN
                                                    },
                                                    maxLength: {
                                                        value: VALIDATIONS.PASSWORD_MAX,
                                                        message: VALIDATIONS_TEXT.PASSWORD_MAX
                                                    },
                                                    pattern: {
                                                        value: /^.*(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
                                                        message: VALIDATIONS_TEXT.INVALID_PASSWORD_FORMAT
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                                    },
                                                })
                                            }

                                        />
                                        <InputGroup.Text>
                                            {
                                                !showPassword ?
                                                    <AiOutlineEye className="eye-icon" onClick={() => setShowPassword(!showPassword)} /> :
                                                    <AiOutlineEyeInvisible className="eye-icon" onClick={() => setShowPassword(!showPassword)} />
                                            }
                                        </InputGroup.Text>
                                    </InputGroup>
                                    {errors.password && <small className='text-red'>{errors.password.message}</small>}
                                </div>

                                <div className='mt-5'>
                                    <Button disabled={isDisabled} type='submit' className="w-100">Add Client</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddByEmailPopup