import React, { useState } from 'react'
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form";
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import OtpInput from "react-otp-input";
import { success, error } from "../../constants/msg";

import Assets from '../../constants/images';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { ResetPassword } from '../../services/auth/auth.services';

const ResetPasswordForm = () => {
    const navigate = useNavigate();
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const location = useLocation();
    const [tempUserData, settempUserData] = useState(location.state)

    const { register, handleSubmit, watch, formState: { errors, isValid } } = useForm({
        mode: "onChange"
    });

    const password = watch("newPassword");

    const onSubmit = async (data) => {
        const { email, code } = tempUserData;
        const { newPassword, confirmPassword } = data;

        setIsDisabled(true)
        try {
            const res = await ResetPassword(email, code, newPassword, confirmPassword);
            success(res.data.message)
            setTimeout(() => {
                navigate("/signin")
                setIsDisabled(false)
            }, 1500)
        }
        catch (e) {
            error(e.response.data.message)
            setTimeout(async () => {
                setIsDisabled(false)
            }, 1500)
        }
    }

    return (
        <div className='forms forgot-password-form mb-2 p-5 py-xl-4 py-xxl-5'>

            <div className='text-center border-bottom pb-4'>
                <img id="logo" className='img-fluid' src={Assets.Logo} alt="Logo" />
            </div>

            <div>
                <div className='text-center mt-4 mb-5'>
                    <h5 className='p-0 m-0'>Reset Your Password</h5>
                    <p className='mt-3'>
                        Enter your new password make different with previous
                    </p>
                </div>

                <div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='mt-4'>
                            <Form.Label>New Password</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    name="newPassword"
                                    type={!showNewPassword ? "password" : "text"}
                                    className="password-input"
                                    placeholder="Enter your new password"
                                    maxLength={VALIDATIONS.PASSWORD_MAX}
                                    {...register("newPassword",
                                        {
                                            minLength: {
                                                value: VALIDATIONS.PASSWORD_MIN,
                                                message: VALIDATIONS_TEXT.PASSWORD_MIN
                                            },
                                            maxLength: {
                                                value: VALIDATIONS.PASSWORD_MAX,
                                                message: VALIDATIONS_TEXT.PASSWORD_MAX
                                            },
                                            pattern: {
                                                value: /^.*(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
                                                message: VALIDATIONS_TEXT.INVALID_PASSWORD_FORMAT
                                            },
                                            required: {
                                                value: true,
                                                message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                            },
                                        })
                                    }

                                />
                                <InputGroup.Text>
                                    {
                                        !showNewPassword ?
                                            <AiOutlineEye className="eye-icon" onClick={() => setShowNewPassword(!showNewPassword)} /> :
                                            <AiOutlineEyeInvisible className="eye-icon" onClick={() => setShowNewPassword(!showNewPassword)} />
                                    }
                                </InputGroup.Text>
                            </InputGroup>
                            {errors.newPassword && <small className='text-red'>{errors.newPassword.message}</small>}
                        </div>

                        <div className='mt-4'>
                            <Form.Label>Confirm New Password</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    name="confirmPassword"
                                    type={!showConfirmPassword ? "password" : "text"}
                                    className="password-input"
                                    placeholder="Enter your confirm password"
                                    maxLength={VALIDATIONS.PASSWORD_MAX}
                                    {...register("confirmPassword",
                                        {
                                            minLength: {
                                                value: VALIDATIONS.PASSWORD_MIN,
                                                message: VALIDATIONS_TEXT.PASSWORD_MIN
                                            },
                                            maxLength: {
                                                value: VALIDATIONS.PASSWORD_MAX,
                                                message: VALIDATIONS_TEXT.PASSWORD_MAX
                                            },
                                            required: {
                                                value: true,
                                                message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                            },
                                            validate: (value) =>
                                                value === password || "The Passwords do not match"
                                        })
                                    }

                                />
                                <InputGroup.Text>
                                    {
                                        !showConfirmPassword ?
                                            <AiOutlineEye className="eye-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)} /> :
                                            <AiOutlineEyeInvisible className="eye-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                                    }
                                </InputGroup.Text>
                            </InputGroup>
                            {errors.confirmPassword && <small className='text-red'>{errors.confirmPassword.message}</small>}
                        </div>

                        <div className='mt-5'>
                            <Button disabled={isDisabled} type="submit" className="w-100">Reset Password</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordForm

