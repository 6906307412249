import React, { useEffect, useState } from 'react'
import { Button, Container, NavLink } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { AddGroupIconMedium } from '../../constants/svgs';
import { GetAuthUserLocalStorage } from '../../services/auth/auth.services';
import CancelSubscriptionPopup from '../popup/CancelSubscriptionPopup';

const SubscriptionPlanCard = ({ navigateLink, showBtn, data, permissions, handleScreen }) => {
    const navigate = useNavigate();
    const [userCurrentSubscription, setUserCurrentSubscription] = useState(null)
    const [deleteSubscriptionPopup, setDeleteSubscriptionPopup] = useState(false)

    useEffect(() => {
        const user = GetAuthUserLocalStorage()
        setUserCurrentSubscription(user.current_subscription)
    }, [])

    return (
        <>
            <CancelSubscriptionPopup cancelSubscriptionPopup={deleteSubscriptionPopup} setCancelSubscriptionPopup={setDeleteSubscriptionPopup} />
            <div className='subscripion-card p-4'>
                <div className='text-center border-bottom pb-2 mb-4'>
                    <h5>{data.name}</h5>
                </div>
                <div>
                    <ul>
                        {
                            permissions.map((v, i) => {
                                return (
                                    (v.type !== 'text') ?
                                        <li key={i} className={(parseInt(v.value) > 0) ? 'show' : ''}>
                                            {v.name}
                                        </li>
                                        :
                                        <li key={i} className={(v.value !== '') && 'show'}>
                                            {v.name}: {v.value}
                                        </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div>
                    {
                        data.is_free &&
                        <div className='text-center border-top pt-4 mt-4 fixed-height'>
                            <h4>{data.name}</h4>
                        </div>
                    }
                    {
                        !data.is_free &&
                        <div className="fixed-height">
                            <div className='d-flex align-items-center justify-content-between border-top pt-4 mt-4'>
                                <h4>{data.currency === 'USD' ? '$ ' + data.monthly_price : data.currency + ' ' + data.monthly_price}</h4>
                                <h4>{data.currency === 'USD' ? '$ ' + data.yearly_price : data.currency + ' ' + data.yearly_price}</h4>
                            </div>

                            <div className='d-flex align-items-center justify-content-between'>
                                <small>Per Month</small>
                                <small>Per Year</small>
                            </div>
                        </div>
                    }
                </div>
                {showBtn &&
                    <div className='text-center mt-5'>
                        <Button onClick={() => navigate('/sub-admin/payment-method', { state: { data: data, navigateLink: navigateLink } })}
                            className='w-100'>{data.id !== userCurrentSubscription?.package?.id ?
                                userCurrentSubscription?.package?.id === 3 ? 'Downgrade' : 'Subscribe'
                                :
                                'Upgrade'
                            } {data.is_free ? 'Free' : ''}</Button>
                        {userCurrentSubscription && data.id === userCurrentSubscription?.package?.id &&
                            <div className="box-small w-100 mt-3" onClick={() => setDeleteSubscriptionPopup(true)}>
                                <NavLink className='m-0 p-0 ms-3'>Cancel Subscription</NavLink>
                            </div>
                        }
                    </div>
                }
            </div>
        </>

    )
}

export default SubscriptionPlanCard
