import React, { useContext } from 'react'
import { Button } from 'react-bootstrap'
import Assets from '../../constants/images'
import { UserDataContext } from "../../App"

const MyProfileCard = ({ user, showPanel }) => {
    return (
        <div className='profile-card p-4 pb-5 w-100'>
            <h4 className='p-0 m-0 border-bottom pb-4 mb-4'>My Profile</h4>
            <div className="img-container mx-auto mb-3">
                <img onError={(e) => e.target.src = Assets.ProfilePlaceHolder} src={user?.details?.image_url ? user.details.image_url : Assets.AvatarLarge} alt="Avatar" />
            </div>

            <div className='text-center'>
                <h5 className='m-0 p-0 pb-3 mb-3 text-break'>{user?.name}</h5>
                <div className='border-bottom pb-3 mb-3'>
                    <h6 className="m-0 p-0">Email:</h6>
                    <h6 className="m-0 mt-1 p-0 text-grey">{user?.email}</h6>
                </div>

                <div className='border-bottom pb-3 mb-3'>
                    <h6 className="m-0 p-0">Phone:</h6>
                    <h6 className="m-0 mt-1 p-0 text-grey">{user?.details?.phone ? user.details.phone : "Not provided"}</h6>
                </div>

                <div className='pb-3 mb-3'>
                    <h6 className="m-0 p-0">Address:</h6>
                    <h6 className="m-0 mt-1 p-0 text-grey">{user?.details?.address ? user.details.address : "Not provided"}</h6>
                </div>

                <div className='mt-5'>
                    <Button onClick={() => showPanel()} className="w-100">Edit Profile</Button>
                </div>
            </div>
        </div>
    )
}

export default MyProfileCard