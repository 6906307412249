import React from 'react'
import { Form } from 'react-bootstrap'
import { StripeIcon } from '../../constants/svgs'

const PaymentCheckCard = ({ id, type, price, showIcon, name, setPaymentType, curType, curPack, packId }) => {
    return (
        <Form.Label className="w-100">
            <div className={`payment-check-card ${name === "same" ? 'p-4' : 'p-3 ps-3 pe-4'} `}>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex w-100 align-items-center'>
                        {
                            showIcon ?
                                <>
                                    <StripeIcon />
                                    <h6 className='p-0 m-0 ms-3 me-3'>Stripe Account</h6>
                                </>
                                :
                                <>
                                    <h6 className='type-heading p-0 m-0'>{type}</h6>
                                    <h4 className='p-0 m-0 ms-3'>{price}</h4>
                                </>
                        }
                    </div>
                    <div className='checkbox-container'>
                        <Form.Check disabled={id == curType && curPack == packId ? true : false} onChange={(e) => setPaymentType(e.target.value)} value={id} readOnly type="radio" name={name} />
                    </div>
                </div>
            </div>
        </Form.Label>
    )
}

export default PaymentCheckCard