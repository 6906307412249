import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Form, Nav, Row, Tab } from "react-bootstrap"
import { useLocation, useParams } from 'react-router-dom'
import ImageCard from '../components/home/ImageCard'
import { MusicCard } from '../components/home/MusicCard'
import PdfFileCard from '../components/home/PdfFileCard'
import TextFileCard from '../components/home/TextFileCard'
import VideoCard from '../components/home/VideoCard'
import PdfViewer from '../components/home/PdfViewer'
import Player from '../components/home/Player'
import ImageViewer from '../components/home/ImageViewer'
import TextViewer from '../components/home/TextViewer'
import { GetResourceBox, GetResourceBoxes, GetResourceClient, GetSpecificEsession } from '../services/auth/auth.services'
import { saveAs } from "file-saver"
import { CurrentSessionContext, ImagePopupContext, UserDataContext } from '../App'
import Loader from '../components/loader/Loader'
import { error } from '../constants/msg'
import Select from 'react-select'
import ImagePopup from '../components/popup/ImagePopup'

const CurrentSessionDescriptionPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [click, setClick] = useState(false)
  const { id } = useParams();
  const [userData, setUserData] = useContext(UserDataContext);
  const [boxes, setBoxes] = useState(null);
  const [clickedImage, setClickedImage] = useState(null)

  useEffect(() => {
    const getResourceClientAndBoxes = async () => {
      try {
        const res = await GetResourceClient(userData.subadmin_user_id)
        let data = res.data.data

        // remove duplicate boxes from data
        data = data.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.box_id == value.box_id && t.reciever_id == value.reciever_id && t.box_id == id
          ))
        )

        setBoxes(...data)
        setIsLoading(false)
      }
      catch (e) {
        error("")
        setIsLoading(false)
      }
    }

    getResourceClientAndBoxes()
  }, [id])

  const downloadFile = (url) => {
    saveAs(url)
  }

  const returnCard = (v, i) => {
    // if (v.type === "text") {
    //   return (
    //     <TextFileCard data={v} key={i} />
    //   )
    // }
    if (v.type === "pdf" || v.type === "document") {
      return (
        <PdfFileCard click={click} setClick={setClick} downloadFile={downloadFile} data={v} key={i} />
      )
    }
    else if (v.type === "image") {
      return (
        <ImageCard click={click} setClick={setClick} downloadFile={downloadFile} data={v} key={i} />
      )
    }
    else if (v.type === "mp3" || v.type === "audio") {
      return (
        <MusicCard click={click} setClick={setClick} downloadFile={downloadFile} data={v} key={i} />
      )
    }
    else if (v.type === "video" || v.type === "mp4" || v.type === "m4v") {
      return (
        <VideoCard click={click} setClick={setClick} downloadFile={downloadFile} data={v} key={i} />
      )
    }
  }

  const returnCardInformation = (v, i) => {
    // if (v.type === "text") {
    //   return (
    //     <TextViewer data={v} key={i} />
    //   )
    // }
    if (v.type === "pdf" || v.type === "document") {
      return (
        <PdfViewer data={v} key={i} />
      )
    }
    else if (v.type === "image") {
      return (
        <ImageViewer data={v} key={i} setClickedImage={setClickedImage} />
      )
    }
    else if (v.type === "mp3" || v.type === "audio") {
      return (
        <Player click={click} setClick={setClick} isAudioPlayer={true} show1="d-block" show2="d-none" paddingY="py-5" data={v} key={i} />
      )
    }
    else if (v.type === "video" || v.type === "mp4" || v.type === "m4v") {
      return (
        <Player isAudioPlayer={false} show1="d-block" show2="d-none" paddingY="py-5" data={v} key={i} />
      )
    }
  }

  return (
    <>
      {
        clickedImage &&
        <ImagePopup data={clickedImage} />
      }

      {
        isLoading ?
          <div className="mt-4 text-center">
            <Loader />
          </div>
          :
          <div className='current-session-description mt-4'>
            {
              boxes ?
                <Tab.Container id="left-tabs-example" defaultActiveKey={`id-${0}`}>
                  <Row>
                    <Col xs={12} xl={`${boxes.resources.length > 0 ? "6" : '12'}`} className="custom-height mb-4 mb-xl-0">
                      <Nav variant="pills" className="flex-column pills-container">
                        <Row>
                          <Col xs={12}>
                            <div className="select-box border-bottom pb-2">
                              <h6 className='m-0 w-500'>{boxes.box.name}</h6>
                            </div>
                          </Col>
                        </Row>

                        <Row className="mt-4">
                          {
                            boxes.resources.map((v, i) => {
                              return (
                                <Col key={i} xs={6} sm={6} md={4} lg={4} xl={4} className="mb-4">
                                  <Nav.Item>
                                    <Nav.Link className='p-0' eventKey={`id-${i}`}>
                                      {
                                        returnCard(v, i)
                                      }
                                    </Nav.Link>
                                  </Nav.Item>
                                </Col>
                              )
                            })
                          }
                        </Row>
                      </Nav>
                    </Col>

                    <Col xs={12} xl={6}>
                      <Tab.Content className="">
                        {
                          boxes?.resources.map((v, i) => {
                            return (
                              <Tab.Pane key={i} className="h-100" eventKey={`id-${i}`}>
                                <div className='right p-2 p-sm-4 h-100'>
                                  <div className='content-container border p-4 h-100'>
                                    <h4 className='title mb-3'>{v.title}</h4>
                                    {returnCardInformation(v, i)}
                                  </div>
                                </div>
                              </Tab.Pane>
                            )
                          })
                        }
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
                :
                <h3 className='text-muted'>No resourses are available</h3>
            }
          </div>
      }
    </>
  )
}

export default CurrentSessionDescriptionPage