import React, { useContext, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { AddGroupPopupContext } from "../App"
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { VALIDATIONS, VALIDATIONS_TEXT } from '../constants/app-constants'
import { useForm } from "react-hook-form";
import { success, error } from "../constants/msg";

import { useLocation, useNavigate } from 'react-router-dom';
import { UserDataContext } from "../App"
import { GetEmailTemplate, SaveEmailTemplate } from '../services/auth/auth.services';
import { useEffect } from 'react';
import Loader from '../components/loader/Loader';

const EmailSetupPage = (props) => {
    const location = useLocation()
    const [userData, setUserData] = useContext(UserDataContext);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [desc, setDesc] = useState("")
    const navigate = useNavigate()
    const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const editorConfiguration = {
        toolbar: ['undo', 'redo', '|', 'bold', '|', 'italic', '|', 'heading', '|', 'outdent', 'indent', '|', 'link', 'numberedList', 'bulletedList',]
    };

    const onSubmit = async (data) => {
        setIsDisabled(true)

        try {
            const res = await SaveEmailTemplate(userData.id, data.description)
            success(res.data.message)
            props.setIntroFlags({...props.introFlags, hasEmailTemplate: true})
            setTimeout(async () => {
                navigate("/sub-admin/email-template")
                setIsDisabled(false)
            }, 1500)
        }
        catch (e) {
            error(e.response.data.errors[0].message)
            setTimeout(async () => {
                setIsDisabled(false)
            }, 1500)
        }
    }

    useEffect(() => {
        const getEmailTemplate = async () => {
            try {
                const res = await GetEmailTemplate(userData.id);
                let description = res.data.data[0].html_body
                setValue('description', description)
                setDesc(description)
                setIsLoading(false)
                if (location.state === null) {
                    navigate("/sub-admin/email-template")
                }
                else {
                    navigate("/sub-admin/email-setup")
                }
            }
            catch (e) {
                setIsLoading(false)
                error(e.response.data.message)
            }
        }
        getEmailTemplate()
    }, [])

    return (
        <>
            {
                isLoading ?
                    <div className="text-center">
                        <Loader />
                    </div>
                    :
                    <div className="common-page email-setup-page">
                        <Row>
                            <Col xs={12}>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='email-setup-container p-4'>
                                        <div id="email-setup-editor">
                                            <CKEditor
                                                {...register('description', {
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.PAGE_CONTENT_REQUIRED
                                                    }
                                                })}
                                                editor={ClassicEditor}
                                                config={editorConfiguration}
                                                data={desc}
                                                onBlur={() => ""}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setValue('description', data)
                                                }}
                                            />
                                        </div>
                                        {errors.description && <div><small className='text-red'>{errors.description.message}</small></div>}

                                        <Button id="save-email-template" disabled={isDisabled} className="mt-4" type="submit">Save Email Template</Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </div>
            }
        </>
    )
}

export default EmailSetupPage