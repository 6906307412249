import React, {useContext, useEffect, useState} from 'react'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'
import SubAdminHeader from '../../../components/home/SubAdminHeader'
import { CalendarIcon } from '../../../constants/svgs'
import {CalendarPopupContext, UserDataContext} from '../../../App';
import CalendarPopup from '../../../components/popup/CalendarPopup';
import {NavLink, useLocation} from 'react-router-dom';
import { Steps } from 'intro.js-react';
import {
    GetAuthUserLocalStorage, GetBoxes, GetEmailTemplate,
    GetEssionsListing,
    GetGroups,
    GetResourceBoxes, GetUsersAddedBySubadmin
} from '../../../services/auth/auth.services.js';
import {error} from "../../../constants/msg.js";
import {roles} from "../../../utils/constants.js";
import introJs from "intro.js";
import { wizardSteps } from "../../../constants/wizard-steps.js";


const SubAdminMainLayout = ({ children, homeLink, breadcrumbLink1, breadcrumbLink2, breadcrumbLink3, show }) => {
    const [handleCalendarPopup, setHandleCalendarPopup] = useContext(CalendarPopupContext);
    const [steps, setSteps] = useState(wizardSteps);
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [startStep, setStartStep] = useState(-1);
    const [lastSavedStep, setLastSavedStep] = useState(0);
    const [triggerStepAction, setTriggerStepAction] = useState(0);
    const [shouldIntroReopen, setShouldIntroReopen] = useState(false);
    const [userData, ] = useContext(UserDataContext);
    const [isRequiredDataFetched, setIsRequiredDataFetched] = useState(false);
    const [introOptions, setIntroOptions] = useState({
        hidePrev: true,
        tooltipClass: 'grahamityTooltip',
        hideNext: false,
        disableInteraction: false
        });
    const [introFlags, setIntroFlags] = useState({
        hasGroup: false,
        hasBox: false,
        hasBoxResource: false,
        hasEmailTemplate: false,
        hasFirstClient: false
    });
    const location = useLocation();


    useEffect( () => {
        const setManageGroupFlags = async (session_id) => {
            const res_resources = await GetResourceBoxes();
            if (res_resources.data.data.length) {
                return {
                    hasGroup: true,
                    hasBox: true,
                    hasBoxResource: true,
                };
            }
            const res_boxes = await GetBoxes(session_id);
            if (res_boxes.data.data.length) {
                return {
                    hasGroup: true,
                    hasBox: true,
                };
            }
            const res_groups = await GetGroups(session_id);
            if (res_groups.data.data.length) {
                return {
                    hasGroup: true,
                };
            }
            return {};
        }
        const setEmailFlags = async () => {
            const res = await GetEmailTemplate(userData.id);
            if (res.data.data.length) {
                return ({ hasEmailTemplate: true });
            }
        }
        const setClientHistoryFlags = async () => {
            const res = await GetUsersAddedBySubadmin(userData.id, roles.ROLE_USER);
            const data = res.data.data.data;
            if (data.length) {
                return { hasFirstClient: true };
            }
        }

        const checkConditionsToSetStep = async () => {
            const res_session = await GetEssionsListing(userData.id);
            if (!res_session.data.data || !res_session.data.data.length) {
                console.error('No session response!');
                return;
            }
            const session_id = res_session.data.data[0].id;
            const manageGroupFlags = {...introFlags, ...await setManageGroupFlags(session_id)};
            const emailFlags = {...manageGroupFlags, ...await setEmailFlags()};
            const clientHistoryFlags = {...emailFlags, ...await setClientHistoryFlags()};
            setIntroFlags(clientHistoryFlags);
        }
        checkConditionsToSetStep().then(() => {
            setIsRequiredDataFetched(true);
        });
    }, []);

    useEffect(() => {
        onCloseIntro();
        setIntroOptions({...introOptions, tooltipClass: 'grahamityTooltip'});
        const onMobile = window.innerWidth < 768;
        if (location.pathname === '/sub-admin/manage-group') {
            setSteps([
                ...wizardSteps.slice(0, 4),
                ...wizardSteps.slice(4, 6)
                    .map((step) => {
                        return {
                            ...step,
                            element: onMobile ? '#account-circle' : '#email-setup-btn'
                        };
                    }),
                ...wizardSteps.slice(6, wizardSteps.length + 1)
                    .map((step) => {
                        return {
                            ...step,
                            element: onMobile ? '#account-circle' : '#client-history-btn'
                        };
                    }),
            ]);
            setIntroOptions({...introOptions, disableInteraction: false});
            return;
        }
        if (location.pathname === '/sub-admin/email-setup') {
            setSteps([
                ...wizardSteps.slice(0, 4)
                    .map((step) => {
                        return {
                            ...step,
                            element: onMobile ? '#account-circle' : '#manage-group-btn'
                        };
                    }),
                ...wizardSteps.slice(4, 6),
                ...wizardSteps.slice(6, wizardSteps.length + 1)
                    .map((step) => {
                        return {
                            ...step,
                            element: onMobile ? '#account-circle' : '#client-history-btn'
                        };
                    }),
            ]);
            return;
        }
        if (location.pathname === '/sub-admin/email-template') {
            setSteps([
                ...wizardSteps.slice(0, 4)
                    .map((step) => {
                        return {
                            ...step,
                            element: onMobile ? '#account-circle' : '#manage-group-btn'
                        };
                    }),
                ...wizardSteps.slice(4, 6)
                    .map((step) => {
                    return {
                        ...step,
                        element: '#edit-email-template'
                    };
                }),
                ...wizardSteps.slice(6, wizardSteps.length + 1)
                    .map((step) => {
                        return {
                            ...step,
                            element: onMobile ? '#account-circle' : '#client-history-btn'
                        };
                    }),
            ]);
            setIntroOptions({...introOptions, disableInteraction: false});
            return;
        }
        if (location.pathname === '/sub-admin/client-history') {
            setSteps([
                ...wizardSteps.slice(0, 4)
                    .map((step) => {
                        return {
                            ...step,
                            element: onMobile ? '#account-circle' : '#manage-group-btn'
                        };
                    }),
                ...wizardSteps.slice(4, 6)
                    .map((step) => {
                        return {
                            ...step,
                            element: onMobile ? '#account-circle' : '#email-setup-btn'
                        };
                    }),
                ...wizardSteps.slice(6, wizardSteps.length + 1),
            ]);
            setIntroOptions({...introOptions, disableInteraction: false});
        }
    }, [location]);

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    const onHelp = async (start) => {
        if (!isRequiredDataFetched) {
            error("Data is loading");
            return;
        }
        if (location.pathname === '/sub-admin/manage-group') {
            setStartStep(start ? start : 0);
        }
        if (location.pathname === '/sub-admin/email-setup'
            || location.pathname === '/sub-admin/email-template') {
            setStartStep(start ? start : 4);
        }
        if (location.pathname === '/sub-admin/client-history') {
            setStartStep(start ? start : 6);
        }
    }

    useEffect(() => {
        if (startStep >= 0) {
            const startAtStep = async () => {
                if (location.pathname === '/sub-admin/client-history') {
                    setTriggerStepAction(1);
                    await delay(200);
                }
                if (location.pathname === '/sub-admin/manage-group') {
                    if (startStep === 2) {
                        setTriggerStepAction(1);
                        await delay(100);
                    }
                    if (startStep === 3) {
                        setTriggerStepAction(2);
                        await delay(100);
                    }
                }
                setStepsEnabled(true);
            }
            startAtStep();
        }
    }, [startStep]);

    const onCloseIntro = () => {
        setStepsEnabled(false);
    }

    const onStopIntro = () => {
        setStepsEnabled(false);
    }

    const onBeforeChange = async (step) => {
        if (location.pathname === '/sub-admin/manage-group') {
            if (step === 1 && !introFlags.hasGroup) {
                setIntroOptions({...introOptions, tooltipClass: 'grahamityTooltipNextHidden'});
                return;
            }
            if (step === 2) {
                if (!introFlags.hasGroup) {
                    return false
                }
                if (!introFlags.hasBox) {
                    setIntroOptions({...introOptions, tooltipClass: 'grahamityTooltipNextHidden'});
                }
                if (triggerStepAction !== 1) {
                    onStopIntro();
                    setTimeout(() => onHelp(2), 100);
                    return false;
                }
                return;
            }
            if (step === 3) {
                if (!introFlags.hasBox) {
                    return false;
                }
                if (!introFlags.hasBoxResource) {
                    setIntroOptions({...introOptions, tooltipClass: 'grahamityTooltipNextHidden'});
                }
                if (triggerStepAction !== 2) {
                    onStopIntro();
                    setTimeout(() => onHelp(3), 100);
                    return false;
                }
                return;
            }
            if (step === 4) {
                if (!introFlags.hasBoxResource) {
                    return false;
                }
                return;
            }
            if (step === 5 && !introFlags.hasEmailTemplate) {
                setIntroOptions({...introOptions, tooltipClass: 'grahamityTooltipNextHidden'});
                return;
            }
            if (step > 5 && !introFlags.hasEmailTemplate) {
                return false
            }
            setIntroOptions({...introOptions, tooltipClass: 'grahamityTooltip'});
        }
        if (location.pathname === '/sub-admin/email-template') {
            if (step === 6 && !introFlags.hasFirstClient) {
                setIntroOptions({...introOptions, tooltipClass: 'grahamityTooltipNextHidden'});
                return;
            }
            if (step === 7 && !introFlags.hasFirstClient) {
                return false;
            }
            setIntroOptions({...introOptions, tooltipClass: 'grahamityTooltip'});
            return;
        }
        if (location.pathname === '/sub-admin/email-setup') {
            setIntroOptions({...introOptions, tooltipClass: 'grahamityTooltip'});
            if (step === 5) {
                setIntroOptions({...introOptions, tooltipClass: 'grahamityTooltipNextHidden'});
            }
            if (step > 5) {
                return false
            }
            return;
        }
        if (location.pathname === '/sub-admin/client-history') {
            if (step === 6 && !introFlags.hasFirstClient) {
                setIntroOptions({...introOptions, tooltipClass: 'grahamityTooltipNextHidden'});
                return;
            }
            if (step === 7 && !introFlags.hasFirstClient) {
                return false;
            }
            setIntroOptions({...introOptions, tooltipClass: 'grahamityTooltip'});
        }
    }

    const childrenWithProps = React.Children.map(children, child => {
        // Checking isValidElement is the safe way and avoids a
        // typescript error too.
        if (React.isValidElement(child)) {
            return React.cloneElement(child,
                {
                    onCloseIntro,
                    triggerStepAction,
                    onStopIntro,
                    onHelp,
                    shouldIntroReopen,
                    setShouldIntroReopen,
                    startStep,
                    stepsEnabled,
                    introFlags,
                    setIntroFlags,
                    lastSavedStep
                })
        }
        return child;
    });

    return (
        <>
            <Steps enabled={stepsEnabled}
                   initialStep={startStep}
                   steps={steps}
                   onExit={(step) => {
                       setStepsEnabled(false);
                       setLastSavedStep(step);
                       setStartStep(-1);
                   }}
                   onBeforeChange={onBeforeChange}
                   options={introOptions}/>
            <div className='main-layout sub-admin-main-layout p-sm-4 p-0 py-3 pb-5'>

                <Container className="h-100 step-one-container">
                    <Row>
                        <Col xs={12}>
                            <SubAdminHeader onHelp={onHelp} onStopIntro={onStopIntro} />
                        </Col>

                        <Col xs={12} className="my-3">
                            <div className="d-flex align-items-sm-center justify-content-between flex-column flex-sm-row">
                                <Breadcrumb>
                                    <Breadcrumb.Item><NavLink to={`/${homeLink}`}>Home</NavLink></Breadcrumb.Item>
                                    {
                                        breadcrumbLink2 !== "" ?
                                            <>
                                                <Breadcrumb.Item><NavLink to="/sub-admin/settings/change-password">{breadcrumbLink1}</NavLink></Breadcrumb.Item>
                                                <Breadcrumb.Item active>{breadcrumbLink2}</Breadcrumb.Item>
                                            </>
                                            :
                                            <>
                                                {
                                                    breadcrumbLink1 !== "" &&
                                                    <Breadcrumb.Item active>{breadcrumbLink1}</Breadcrumb.Item>
                                                }
                                            </>
                                    }
                                    {
                                        breadcrumbLink3 &&
                                        <>
                                            <Breadcrumb.Item active>{breadcrumbLink3}</Breadcrumb.Item>
                                        </>
                                    }
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>

                    {childrenWithProps}
                </Container>
            </div>
        </>
    )
}

export default SubAdminMainLayout