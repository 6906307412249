import React, { useContext, useEffect, useMemo, useState } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Col, Form, InputGroup, Row, Button, Tabs, Tab } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { CalendarIcon } from "../constants/svgs";
import { CalendarPopupContext, UserDataContext } from "../App";
import CalendarPopup from "../components/popup/CalendarPopup";
import { VALIDATIONS, VALIDATIONS_TEXT } from "../constants/app-constants";
import { alpha, styled } from "@mui/material/styles";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AddClientPopupContext, RemoveHistoryPopupContext } from "../App";
import AddByEmailPopup from "../components/popup/AddByEmailPopup";
import AddClientPopup from "../components/popup/AddClientPopup";
import { MdOutlineMail } from "react-icons/md";
import {
    GetResourceClient,
    GetUsers,
    GetUsersAddedBySubadmin,
} from "../services/auth/auth.services";
import { success, error } from "../constants/msg";

import RemoveHistoryPopup from "../components/popup/RemoveHistoryPopup";
import { roles } from "../utils/constants";

const ODD_OPACITY = 0.2;

const ClientHistoryPage = (props) => {
    const [handleRemoveHistoryPopup, setHandleRemoveHistoryPopup] = useContext(RemoveHistoryPopupContext);
    const [userData, setUserData] = useContext(UserDataContext)
    const [handleCalendarPopup, setHandleCalendarPopup] = useContext(CalendarPopupContext);
    const [handleAddClientPopup, setHandleAddClientPopup] = useContext(AddClientPopupContext);
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [text, setText] = useState("")
    const [patternError, setPatternError] = useState(false)
    const [rows, setRows] = useState([])
    const [tempArray, setTempArray] = useState([])
    const [selectedHistory, setSelectedHistory] = useState("")
    const [uploadedClientData, setUploadedClientData] = useState([])
    const [active, setActive] = useState(true)
    const [count, setCount] = useState(0)
    const [isFiltered, setIsFiltered] = useState(false)
    const [isFilteredByEmail, setFilteredByEmail] = useState(false)

    const { register, handleSubmit } = useForm({
        mode: "onChange"
    });

    const onSubmit = () => { };

    const columnsHistory = [
        {
            field: "box_name",
            headerName: "Box Name",
            minWidth: 400,
            flex: 1,
            // sortable:false
        },
        {
            field: "session",
            headerName: "Session",
            minWidth: 250,
            flex: 1,
            // sortable:false
        },
        {
            field: "professional",
            headerName: "Professional",
            minWidth: 150,
            flex: 1,
            // sortable:false
        },
        // {
        //     field: "action",
        //     headerName: "Action",
        //     minWidth: 150,
        //     flex: 1,
        //     renderCell: (params) => (
        //         <div>
        //             <Button
        //                 onClick={() => {
        //                     setHandleRemoveHistoryPopup(true);
        //                     setSelectedHistory(params.id);
        //                 }}
        //                 className="delete-btn"
        //             >
        //                 <RiDeleteBin6Line />
        //             </Button>
        //         </div>
        //     ),
        // },
    ];

    const columnsClient = [
        {
            field: "name",
            headerName: "Name",
            minWidth: 280,
            flex: 1,
            // sortable:false
        },
        {
            field: "email",
            headerName: "Email",
            minWidth: 280,
            flex: 1,
            // sortable:false
        },
        {
            field: "phone",
            headerName: "Phone",
            minWidth: 150,
            flex: 1,
            // sortable:false
        },
        // {
        //     field: "location",
        //     headerName: "Location",
        //     minWidth: 150,
        //     flex: 1,
        //     // sortable:false
        // },
    ];

    const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
        [`& .${gridClasses.row}.even`]: {
            backgroundColor: "#f4f9fb",
        },
    }));

    const NewStripedDataGrid = styled(DataGrid)(({ theme }) => ({
        [`& .${gridClasses.row}.even`]: {
            backgroundColor: "#f4f9fb",
        },
    }));

    const handleFilter = (date) => {
        let filteredData = tempArray.filter((data) => {
            return data.send_date === date;
        });
        setRows(filteredData);
    };

    const clearFilter = () => {
        setRows(tempArray)
        setIsFiltered(false)
    }

    const clearFilterByEmail = () => {
        setRows(tempArray)
        setSelectedUser(null)
        setFilteredByEmail(false)
    }

    const handleFilterByEmail = (selected_data) => {
        let filteredData = tempArray.filter((data) => {
            return data.client_email === selected_data.email;
        });
        setFilteredByEmail(true)
        setRows(filteredData)
        // setTempArray(filteredData)
    };

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const returnSessionName = (date, session_id) => {
        let str = "";
        let month = "";
        date = date.split("-");

        if (date[1] == "01" || date[1] == "1") {
            month = "Jan";
        } else if (date[1] == "02" || date[1] == "2") {
            month = "Feb";
        } else if (date[1] == "03" || date[1] == "3") {
            month = "Mar";
        } else if (date[1] == "04" || date[1] == "4") {
            month = "Apr";
        } else if (date[1] == "05" || date[1] == "5") {
            month = "May";
        } else if (date[1] == "06" || date[1] == "6") {
            month = "Jun";
        } else if (date[1] == "07" || date[1] == "7") {
            month = "Jul";
        } else if (date[1] == "08" || date[1] == "8") {
            month = "Aug";
        } else if (date[1] == "09" || date[1] == "9") {
            month = "Sep";
        } else if (date[1] == "10" || date[1] == "10") {
            month = "Oct";
        } else if (date[1] == "11" || date[1] == "11") {
            month = "Nov";
        } else {
            month = "Dec";
        }

        str = `Company ` + month + "-" + date[2] + "-" + date[0];
        return str;
    };

    useEffect(() => {
        const getResourceClient = async () => {
            let array = [];
            try {
                await GetResourceClient(userData.id).then((res) => {
                    let data = res.data.data;
                    data.map((v, i) => {
                        array.push({
                            id: v.id,
                            box_name: v.box?.name || "-",
                            session: returnSessionName(
                                v.created_at?.split(" ")[0],
                                v.box?.session_id
                            ),
                            send_date: v.created_at?.split(" ")[0] || "-",
                            professional: v.sender?.name || "-",
                            client_email: v.reciever?.email,
                            action: "",
                        });
                    });
                    setRows(array);
                    setTempArray(array);
                    array = [];
                });
            } catch (e) {
                error(e.response.data.message);
            }
        };
        getResourceClient();
    }, []);

    useEffect(() => {
        const getUsersAddedBySubadmin = async () => {
            let array = [];
            try {
                await GetUsersAddedBySubadmin(userData.id, roles.ROLE_USER).then(
                    (res) => {
                        let data = res.data.data.data;
                        data.map((v, i) => {
                            array.push({
                                id: v?.id,
                                name: v?.name || "-",
                                email: v?.email || "-",
                                phone: v?.details?.phone || "-",
                                // location: v?.details?.address || "-",
                            });
                        });
                        setUploadedClientData(array);
                        setUsers(data);
                    }
                );
            } catch (e) {
                error(e.response.data.errors[0].message);
            }
        };

        getUsersAddedBySubadmin();
    }, [count]);

    const filterdData = users.filter((value) => {
        return value.email.toLowerCase().includes(text.toLowerCase());
    });

    const HistoryComponent = () => {
        return (
            <div style={{ height: 595 }} className="w-100">
                <StripedDataGrid
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                    }
                    className="data-table"
                    rows={rows}
                    columns={columnsHistory}
                />
            </div>
        );
    };

    const ClientComponent = () => {
        return (
            <div style={{ height: 595 }} className="mt-3 w-100">
                <NewStripedDataGrid
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                    }
                    className="data-table"
                    rows={uploadedClientData}
                    columns={columnsClient}
                />
            </div>
        );
    };

    useEffect(() => {
        if (props.triggerStepAction === 1) {
            setActive(false);
        }
    }, [props.triggerStepAction]);

    const addNewCount = (newCount) => {
        setCount(newCount);
        if (props.shouldIntroReopen && newCount > 0) {
            props.setIntroFlags({...props.introFlags, hasFirstClient: true});
            props.setShouldIntroReopen(false);
            props.onHelp(7);
        }
        console.log(props.shouldIntroReopen);
    }

    const MemoizedHistoryComponent = useMemo(() => HistoryComponent, [rows]);
    const MemoizedClientComponent = useMemo(() => ClientComponent, [uploadedClientData]);

    return (
        <div className='client-history-page'>
            <CalendarPopup handleFilter={handleFilter} setIsFiltered={setIsFiltered} />
            <AddByEmailPopup count={count} setCount={addNewCount} />
            <AddClientPopup count={count} setCount={addNewCount} />
            <RemoveHistoryPopup rows={rows} setRows={setRows} setTempArray={setTempArray} selectedHistory={selectedHistory} />

            <Row>
                <Col md={6} lg={4} xxl={3} className="mb-4">
                    <div className={`left h-100`}>
                        <div
                            className={`search-container p-3 h-100 ${!active && "d-flex align-items-center justify-content-center"
                                }`}
                        >
                            <div className={`holder p-4 mb-5 ${!active && "d-none"} `}>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Label>Search Client</Form.Label>
                                    <InputGroup className="">
                                        <Form.Control
                                            onChange={(e) => {
                                                if (!isValidEmail(e.target.value)) {
                                                    setPatternError(true);
                                                } else {
                                                    setPatternError(false);
                                                }
                                                setText(e.target.value);
                                            }}
                                            value={text}
                                            name="search"
                                            type="text"
                                            autocomplete="off"
                                            placeholder="Lookup client history by e-mail"
                                        />
                                    </InputGroup>
                                    {(filterdData.length <= 0 && text.length > 0) &&
                                        <small className='text-red'>No result found</small>}

                                    {text.length > 0 && filterdData.length > 0 && (
                                        <div className="suggestion-holder bg-white">
                                            <div className="search-suggestion">
                                                <ul>
                                                    {
                                                        filterdData.map((v, i) => (
                                                            <li
                                                                key={i}
                                                                onClick={() => {
                                                                    setText("")
                                                                    setSelectedUser(v)
                                                                    handleFilterByEmail(v)
                                                                }}
                                                            ><MdOutlineMail className='text-dark me-3' />{v.email}</li>
                                                        ))

                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    )}

                                    <Button type="submit" className="mt-4 w-100">
                                        Search
                                    </Button>
                                </Form>
                            </div>

                            <div className={`holder p-4 ${!active && "d-none"}`}>
                                <Row>
                                    <Col
                                        xs={5}
                                        lg={12}
                                        xl={5}
                                        xxl={6}
                                        className="mb-lg-2 mb-xl-4 mb-4"
                                    >
                                        <h6 className="fw-600 p-0 m-0">Current Client: </h6>
                                    </Col>
                                    <Col xs={7} lg={12} xl={7} xxl={6} className="mb-4">
                                        <h6 className="p-0 m-0">
                                            {selectedUser ? selectedUser.name : "-"}
                                        </h6>
                                    </Col>

                                    <Col
                                        xs={5}
                                        lg={12}
                                        xl={5}
                                        xxl={6}
                                        className="mb-lg-2 mb-xl-4 mb-4"
                                    >
                                        <h6 className="fw-600 p-0 m-0">Email: </h6>
                                    </Col>
                                    <Col xs={7} lg={12} xl={7} xxl={6} className="mb-4">
                                        <h6 className="p-0 m-0">
                                            {selectedUser ? selectedUser.email : "-"}
                                        </h6>
                                    </Col>

                                    <Col
                                        xs={5}
                                        lg={12}
                                        xl={5}
                                        xxl={6}
                                        className="mb-lg-2 mb-xl-4 mb-4"
                                    >
                                        <h6 className="fw-600 p-0 m-0">Phone#: </h6>
                                    </Col>
                                    <Col xs={7} lg={12} xl={7} xxl={6} className="mb-4">
                                        <h6 className="p-0 m-0">
                                            {selectedUser ? selectedUser.details.phone : "-"}
                                        </h6>
                                    </Col>

                                    <Col
                                        xs={5}
                                        lg={12}
                                        xl={5}
                                        xxl={6}
                                        className="mb-lg-2 mb-xl-4 mb-4"
                                    >
                                        <h6 className="fw-600 p-0 m-0">Address: </h6>
                                    </Col>
                                    <Col xs={7} lg={12} xl={7} xxl={6} className="mb-4">
                                        <h6 className="p-0 m-0">
                                            {selectedUser ? selectedUser.details.address : "-"}
                                        </h6>
                                    </Col>
                                    <Col xs={12} lg={12} xl={12} xxl={12} className="mb-4">
                                        {
                                            isFilteredByEmail ?
                                                <Button className="mt-4 w-100" onClick={() => clearFilterByEmail()}>Clear Filter by Email</Button>
                                                :
                                                ''
                                        }
                                    </Col>
                                </Row>
                            </div>

                            <div className={`w-100 ${active ? "d-none" : "d-block"}`}>
                                <Row>
                                    <Col xs={12}>
                                        <Button
                                            id="add-client-btn"
                                            onClick={() => {
                                                props.onCloseIntro();
                                                if (props.stepsEnabled) {
                                                    props.setShouldIntroReopen(true);
                                                }
                                                setHandleAddClientPopup(true);
                                            }}
                                            className="w-100"
                                        >
                                            Add Client
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col md={6} lg={8} xxl={9} className="mb-4">
                    <div className="right">
                        <Row>
                            <Col xs={12}>
                                <div className="table-container p-3">
                                    <Col xs={12}>
                                        <span className={`btns-tabs pb-1 ${active ? "active" : ""}`}
                                            onClick={() => setActive(true)}>History</span>
                                        <span className={`btns-tabs pb-1  ${active ? "" : "active"}`}
                                            onClick={() => setActive(false)}>Clients</span>

                                        {
                                            active ?
                                                <>
                                                    {
                                                        isFiltered ?
                                                            <div className='text-end'>
                                                                <Button className="btn-clear-filter mb-3 mt-3" onClick={() => clearFilter()}>Clear Filter</Button>
                                                            </div>
                                                            :
                                                            <div className="border mb-3 mt-3 box d-flex p-2" onClick={() => setHandleCalendarPopup(true)}>
                                                                <span className='d-inline-block me-4'>Filter By Date</span>
                                                                <CalendarIcon />
                                                            </div>
                                                    }
                                                    <MemoizedHistoryComponent />
                                                </>
                                                :
                                                <MemoizedClientComponent />
                                        }
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ClientHistoryPage;
