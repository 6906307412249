import React, { useState, useEffect, useContext } from 'react'
import { Route, Navigate, Outlet } from "react-router";
import { GetAuthUserLocalStorage, GetTokenLocalStorage } from "./services/auth/auth.services"

const ProtectedRoute = ({ requiredRole, checkSubscription = 0 }) => {
    const token = GetTokenLocalStorage()
    const user = GetAuthUserLocalStorage()
    return (
        checkSubscription === 1 ?
            (user.current_subscription === null ? <Navigate to="/sub-admin/subscription" /> :
                (token && (requiredRole[0] === user.role_id || requiredRole[1] === user.role_id || requiredRole[2] === user.role_id)) ? <Outlet /> : <Navigate to="/signin" />) :
            (token && (requiredRole[0] === user.role_id || requiredRole[1] === user.role_id || requiredRole[2] === user.role_id)) ? <Outlet /> : <Navigate to="/signin" />
    )
}

export default ProtectedRoute