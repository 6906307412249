import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import Assets from '../../constants/images';
import { useForm } from "react-hook-form";
import { VALIDATIONS, VALIDATIONS_TEXT, GENERAL_ERROR_MESSAGES } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";

import { useNavigate, NavLink } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { ForgetPasswordRequest, GetAuthUserLocalStorage, Login, StorageDeviceToken } from "../../services/auth/auth.services"
import { roles, constant } from "../../utils/constants"
import { SetTokenLocalStorage, SetAuthUserLocalStorage } from "../../services/auth/auth.services"
import { UserDataContext } from "../../App"

const SigninForm = ({ btnText, isForgetText }) => {
    const navigate = useNavigate();
    const [userData, setUserData] = useContext(UserDataContext);
    const [showPassword, setShowPassword] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [deviceToken, setDeviceToken] = useState(null);
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        const getStorageDeviceToken = async () => {
            const res = await StorageDeviceToken();
            setDeviceToken(JSON.parse(res))
        }
        getStorageDeviceToken()
    }, [])

    const onSubmit = async (data) => {
        let { email, password } = data
        setIsDisabled(true)
        try {
            const res = await Login(email, password, deviceToken ? deviceToken.device_type : constant.DEVICE_TYPE, deviceToken ? deviceToken.device_token : constant.DEVICE_TOKEN)
            if (res.data.data.role_id === roles.ROLE_PROFESSIONAL) {
                error(GENERAL_ERROR_MESSAGES.PROFESSIONAL_LOGIN)
            } else {
                success(res.data.message)
                if (res.data.data.role_id === roles.ROLE_USER) {
                    setTimeout(() => {
                        setUserData(res.data.data)
                        SetTokenLocalStorage(res.data.data.access_token)
                        SetAuthUserLocalStorage(res.data.data)
                        navigate(`/current-session`)
                    }, 1500)
                    // if (res.data.data.verified == 1) {
                    //     setTimeout(() => {
                    //         setUserData(res.data.data)
                    //         SetTokenLocalStorage(res.data.data.access_token)
                    //         SetAuthUserLocalStorage(res.data.data)
                    //         navigate(`/current-session`)
                    //     }, 1500)
                    // }
                    // else {
                    //     setTimeout(() => {
                    //         navigate(`/verification`, { state: { user: res.data.data, navigateLink: "current-session", isResetPassword: false } })
                    //     }, 1500)
                    // }
                }
                else {
                    if (res.data.data.verified === 1) {
                        setTimeout(() => {
                            if (res.data.data.current_subscription) {
                                if (res.data.data.current_subscription.package_id === 3 && res.data.data.user_2fa === false) {
                                    navigate(`/verification`, { state: { user: res.data.data, navigateLink: "sub-admin/manage-group", isResetPassword: false, TwoFa: true } })
                                } else {
                                    setUserData(res.data.data)
                                    SetTokenLocalStorage(res.data.data.access_token)
                                    SetAuthUserLocalStorage(res.data.data)
                                    navigate(`/sub-admin/manage-group`)
                                }
                            }
                            else {
                                setUserData(res.data.data)
                                SetTokenLocalStorage(res.data.data.access_token)
                                SetAuthUserLocalStorage(res.data.data)
                                navigate(`/sub-admin/subscription`)
                            }
                        }, 1500)
                    }
                    else {
                        if (res.data.data.current_subscription) {
                            setTimeout(() => {
                                navigate(`/verification`, { state: { user: res.data.data, navigateLink: "sub-admin/manage-group", isResetPassword: false } })
                            }, 1500)
                        }
                        else {
                            setTimeout(() => {
                                navigate(`/verification`, { state: { user: res.data.data, navigateLink: "sub-admin/subscription", isResetPassword: false } })
                            }, 1500)
                        }
                    }
                }
            }
        }
        catch (e) {
            error(e.response.data.message)
            setTimeout(async () => {
                setIsDisabled(false)
            }, 1500)
        }
    }

    return (
        <div className='forms signin-form p-5 py-xl-4 py-xxl-5 mb-4'>
            <div className='text-center border-bottom pb-4'>
                <img id="logo" className='img-fluid' src={Assets.Logo} alt="Logo" />
            </div>
            <div>
                <div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='mt-4'>
                            <Form.Label>E-Mail Address  *</Form.Label>
                            <InputGroup className=''>
                                <Form.Control
                                    name="email"
                                    type="email"
                                    placeholder="Enter email address *"
                                    maxLength={VALIDATIONS.EMAIL}
                                    {...register("email",
                                        {
                                            maxLength: {
                                                value: VALIDATIONS.EMAIL,
                                                message: VALIDATIONS_TEXT.EMAIL_MAX
                                            },
                                            required: {
                                                value: true,
                                                message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                            },
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                            }
                                        })
                                    }
                                />
                            </InputGroup>
                            {errors.email && <small className='text-red'>{errors.email.message}</small>}
                        </div>

                        <div className='mt-4'>
                            <Form.Label>Password  *</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    name="password"
                                    className="password-input"
                                    placeholder="Password *"
                                    type={!showPassword ? "password" : "text"}
                                    maxLength={VALIDATIONS.PASSWORD_MAX}
                                    {...register("password",
                                        {
                                            minLength: {
                                                value: VALIDATIONS.PASSWORD_MIN,
                                                message: VALIDATIONS_TEXT.PASSWORD_MIN
                                            },
                                            maxLength: {
                                                value: VALIDATIONS.PASSWORD_MAX,
                                                message: VALIDATIONS_TEXT.PASSWORD_MAX
                                            },
                                            required: {
                                                value: true,
                                                message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                            },
                                        })
                                    }
                                />
                                <InputGroup.Text>
                                    {
                                        !showPassword ?
                                            <AiOutlineEye className="eye-icon" onClick={() => setShowPassword(!showPassword)} /> :
                                            <AiOutlineEyeInvisible className="eye-icon" onClick={() => setShowPassword(!showPassword)} />
                                    }
                                </InputGroup.Text>
                            </InputGroup>
                            {errors.password && <small className='text-red'>{errors.password.message}</small>}
                        </div>

                        <div className='text-center'>
                            <Button disabled={isDisabled} className="w-100 mt-4 btn-signin" type="submit">{btnText}</Button>
                        </div>

                        {
                            isForgetText &&
                            <div className='mt-4 text-center'>
                                <NavLink to="/forgot-password">Forgot Password?</NavLink>
                            </div>
                        }

                        <p className='text-center mt-4 mb-0'>Don't have an account? <NavLink className="link text-end" to="/signup">Sign Up</NavLink></p>

                    </Form>
                </div>
            </div>
        </div>
    )
}

export default SigninForm