import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Accordion, Button, Col, OverlayTrigger, ProgressBar, Row, Tooltip } from 'react-bootstrap'
import { AddGroupIcon, AddGroupIconMedium, AddGroupIconSmall } from '../constants/svgs'
import { RemoveGroupPopupContext, RemoveBoxPopupContext, AddBoxPopupContext, AddGroupPopupContext, RemoveResourcePopupContext, EditGroupPopupContext, EditBoxPopupContext, EditDeleteGroupPopupContext, RemoveDeleteGroupPopupContext, AddSessionPopupContext, CurrentSessionContext } from '../App'
import AddGroupPopup from '../components/popup/AddGroupPopup'
import AddBoxPopup from '../components/popup/AddBoxPopup'
import AudioCardSmall from '../components/home/AudioCardSmall'
import ImageCardSmall from '../components/home/ImageCardSmall'
import VideoCardSmall from '../components/home/VideoCardSmall'
import PdfCardSmall from '../components/home/PdfCardSmall'
import ImagePopup from '../components/popup/ImagePopup'
import PdfPopup from '../components/popup/PdfPopup'
import AudioPopup from '../components/popup/AudioPopup'
import VideoPopup from '../components/popup/VideoPopup'
import { EditBox, EditGroup, GetAuthUserLocalStorage, GetBoxes, GetCurrentUser, GetDeletedGroupBoxes, GetEssionsListing, GetGroups, GetResourceBoxes, SaveResourceInBoxes, SaveResourceInDeletedGroupBoxes, SaveResourceInGroups, SetAuthUserLocalStorage, UploadEresource } from '../services/auth/auth.services'
import { success, error } from "../constants/msg";
import introJs from 'intro.js';

import RemoveResourcePopup from '../components/popup/RemoveResourcePopup'
import { UploadFileToS3, UploadFileToS3WithProgressBar } from '../utils/helpers'
import { UserDataContext } from "../App"
import Loader from '../components/loader/Loader'
import { s3Credential } from '../utils/constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { constant } from "../utils/constants"
import { RiDeleteBin6Line, RiEdit2Line, RiSortAsc, RiSortDesc } from 'react-icons/ri'
import { AccordianIcon } from "../constants/svgs"
import RemoveGroupPopup from "../components/popup/RemoveGroupPopup"
import RemoveBoxPopup from "../components/popup/RemoveBoxPopup"
import EditGroupPopup from '../components/popup/EditGroupPopup'
import EditBoxPopup from '../components/popup/EditBoxPopup'
import { BsFillInfoCircleFill } from "react-icons/bs"
import EditDeletedBoxPopup from '../components/popup/EditDeletedBoxPopup'
import RemoveDeletedBoxPopup from '../components/popup/RemoveDeletedBoxPopup'
import AddSessionPopup from '../components/popup/AddSessionPopup'
import EmailSetupPage from './EmailSetupPage'
import { FaTimesCircle } from 'react-icons/fa'
import { MdDriveFileMoveOutline } from 'react-icons/md'
import MoveDeletedBoxPopup from '../components/popup/MoveDeletedBoxPopup'
import { TOOL_TIP_MESSAGES } from '../constants/app-constants'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const ManageGroupPage = (props) => {
    const navigate = useNavigate();
    const [placement, setPlacement] = useState("")
    const fileUploadRefGroup = useRef(null)
    const fileUploadRefBox = useRef(null)
    const [isDisabledGroup, setIsDisabledGroup] = useState(false)
    const [isDisabledBox, setIsDisabledBox] = useState(false)
    const [loadingGroup, setLoadingGroup] = useState(false);
    const [loadingBox, setLoadingBox] = useState(false);
    const [loadingDeletedGroupBox, setLoadingDeletedGroupBox] = useState(false);
    const [session, setSession] = useState()
    const [groups, setGroups] = useState([])
    const [boxes, setBoxes] = useState([])
    const [resourceBoxes, setResourceBoxes] = useState([])
    const [mergeGroupAndBoxes, setMergeGroupAndBoxes] = useState([])

    const [showSelectedResource, setShowSelectedResource] = useState(null)
    const [selectedResource, setSelectedResource] = useState(null)
    const [deletedGroupData, setDeletedGroupData] = useState([])
    const [activeDeletedGroupBox, setActiveDeletedGroupBox] = useState(false)
    const [count, setCount] = useState(0)
    const [accordionActiveKey, setAccordionActiveKey] = useState(null)

    const [selectedGroup, setSelectedGroup] = useState({
        index: "",
        id: "",
    })

    const [selectedBox, setSelectedBox] = useState({
        index: "",
        id: "",
        name: "",
        group_index: "",
        group_id: ""
    })

    const [fileSize, setFileSize] = useState(0)
    const [now, setNow] = useState(0)
    const [now2, setNow2] = useState(0)
    // *********************** working context ***********************

    const [handleRemoveGroupPopup, setHandleRemoveGroupPopup] = useContext(RemoveGroupPopupContext);
    const [handleRemoveBoxPopup, setHandleRemoveBoxPopup] = useContext(RemoveBoxPopupContext);
    const [handleAddGroupPopup, setHandleAddGroupPopup] = useContext(AddGroupPopupContext);
    const [handleAddBoxPopup, setHandleAddBoxPopup] = useContext(AddBoxPopupContext);
    const [handleEditGroupPopup, setHandleEditGroupPopup] = useContext(EditGroupPopupContext);
    const [handleEditBoxPopup, setHandleEditBoxPopup] = useContext(EditBoxPopupContext);
    const [userData, setUserData] = useContext(UserDataContext);

    const [handleEditDeletedGroupPopup, setHandleEditDeletedGroupPopup] = useContext(EditDeleteGroupPopupContext);
    const [handleRemoveDeletedGroupPopup, setHandleRemoveDeletedGroupPopup] = useContext(RemoveDeleteGroupPopupContext);
    const [handleMoveDeletedBoxPopup, setHandleMoveDeletedBoxPopup] = useState(false);


    const [handleCurrentSession, setHandleCurrentSession] = useContext(CurrentSessionContext);
    const [handleAddSessionPopup, setHandleAddSessionPopup] = useContext(AddSessionPopupContext);
    const [sessionCreated, setSessionCreated] = useState(false);

    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)

    // *********************** working context ***********************


    // States to set "url's" of different resources inside popup
    const [pdfPopupItem, setPdfPopupItem] = useState({ file_url: "" });
    const [imagePopupItem, setImagePopupItem] = useState({ file_url: "" });
    const [videoPopupItem, setVideoPopupItem] = useState({ file_url: "" });
    const [audioPopupItem, setAudioPopupItem] = useState({ file_url: "", title: "" });
    const [userCurrentSubscription, setUserCurrentSubscription] = useState(true)
    const [load, setLoad] = useState(true)
    const [sortingOrder, setSortingOrder] = useState('asc')
    const [isDropDisabled, setIsDropDisabled] = useState(false)

    const returnCardInformation = (v, i) => {
        // if (v.type === "text") {
        //   return (
        //     <TextViewer data={v} key={i} />
        //   )
        // }
        if (v.type === "pdf" || v.type === "document") {
            return (
                <PdfCardSmall setSelectedResource={setSelectedResource} setPdfPopupItem={setPdfPopupItem} data={v} key={i} />
            )
        }
        else if (v.type === "image") {
            return (
                <ImageCardSmall setSelectedResource={setSelectedResource} setImagePopupItem={setImagePopupItem} data={v} key={i} />
            )
        }
        else if (v.type === "mp3" || v.type === "audio") {
            return (
                <AudioCardSmall setSelectedResource={setSelectedResource} setAudioPopupItem={setAudioPopupItem} isAudioPlayer={true} show1="d-block" show2="d-none" paddingY="py-5" data={v} key={i} />
            )
        }
        else if (v.type === "video" || v.type === "mp4" || v.type === "m4v") {
            return (
                <VideoCardSmall setSelectedResource={setSelectedResource} setVideoPopupItem={setVideoPopupItem} isAudioPlayer={false} show1="d-block" show2="d-none" paddingY="py-5" data={v} key={i} />
            )
        }
    }

    const returnFileType = (file) => {
        let fileType = file.name.split(".")
        fileType = fileType[fileType.length - 1].toLowerCase()

        if (fileType === "png" || fileType === "jpg" || fileType === "jpeg" || fileType === "jfif" || fileType === "webp") {
            fileType = "image"
        }
        else if (fileType === "pdf" || fileType === "docx" || fileType === "doc" || fileType === "txt" || fileType === "xlsx" || fileType === "tiff") {
            fileType = "document"
        }
        else if (fileType === "mp3") {
            fileType = "mp3"
        }
        else if (fileType === "mp4" || fileType === "mov" || fileType === "m4v") {
            fileType = "video"
        }
        else {
            fileType = "invalid"
        }
        return fileType
    }

    const handleFileUploadInBox = async (e, box_id, group_id) => {
        let file = e.target.files[0]

        if (file) {
            setFileSize(file.size)
            setIsDisabledBox(true)
            setLoadingBox(true)
            let fileType = returnFileType(file)
            if (fileType == "invalid") {
                error("Invalid file type")
                fileUploadRefBox.current.value = null
                setLoadingBox(false)
                setTimeout(() => {
                    setIsDisabledBox(false)
                }, 1500)
            }
            else {
                try {
                    await UploadFileToS3WithProgressBar(file, constant.resourceDirName)
                        .on('httpUploadProgress', function (progress) {
                            let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                            setNow(progressPercentage)
                        })
                        .promise().then(async (document) => {
                            let title = file.name
                            let description = "Dummy"
                            let url = document.Key

                            await UploadEresource(title, description, url, fileType, fileSize, userData.id).then(async (res) => {
                                const resourse_id = res.data.data.id
                                await SaveResourceInBoxes(resourse_id, box_id, group_id).then((response) => {
                                    const uploadResourceData = res.data.data
                                    const resourseBoxData = response.data.data
                                    let obj = {
                                        box: {
                                            id: resourseBoxData.box_id,
                                            group_id: resourseBoxData.group_id
                                        },
                                        box_id: resourseBoxData.box_id,
                                        group: {
                                            id: resourseBoxData.group_id
                                        },
                                        group_id: resourseBoxData.group_id,
                                        id: uploadResourceData.id,
                                        resource: {
                                            ...uploadResourceData,
                                            resource_boxId: resourseBoxData.id
                                        },
                                        resource_id: resourseBoxData.resource_id,
                                        resource_boxId: resourseBoxData.id
                                    }
                                    updateStateOnFileUpload(obj, resourseBoxData.group_id, resourseBoxData.box_id, false)
                                    fileUploadRefBox.current.value = null
                                    setTimeout(() => {
                                        setIsDisabledBox(false)
                                        // success(response.data.message)
                                    }, 1100);

                                    setLoadingBox(false)
                                    setNow(0)

                                }).catch((e) => {
                                    fileUploadRefBox.current.value = null
                                    error(e.response.data.message)
                                    setTimeout(() => {
                                        setIsDisabledBox(false)
                                    }, 1000);
                                    setLoadingBox(false)
                                })
                            }).catch((e) => {
                                fileUploadRefBox.current.value = null
                                error("File is too large")
                                setTimeout(() => {
                                    setIsDisabledBox(false)
                                }, 1000);
                                setLoadingBox(false)
                            })
                        })
                }
                catch (e) {
                    fileUploadRefBox.current.value = null
                    setTimeout(() => {
                        setIsDisabledBox(false)
                    }, 1000);
                }
            }
        }
    }

    const handleFileUploadInDeletedGroupBox = async (e, box_id) => {
        let file = e.target.files[0]

        if (file) {
            setFileSize(file.size)
            setIsDisabledBox(true)
            setLoadingDeletedGroupBox(true)
            let fileType = returnFileType(file)
            if (fileType == "invalid") {
                error("Invalid file type")
                fileUploadRefBox.current.value = null
                setLoadingDeletedGroupBox(false)
                setTimeout(() => {
                    setIsDisabledBox(false)
                }, 1500)
            }
            else {
                try {
                    await UploadFileToS3WithProgressBar(file, constant.resourceDirName).then(async (document) => {
                        const title = file.name
                        const description = "Dummy"
                        const fileSize = file.size

                        await UploadEresource(title, description, document.url, fileType, fileSize, userData.id).then(async (res) => {
                            const resourse_id = res.data.data.id
                            await SaveResourceInDeletedGroupBoxes(resourse_id, box_id).then((response) => {
                                const uploadResourceData = res.data.data
                                const resourseBoxData = response.data.data
                                let obj = {
                                    box: {
                                        id: resourseBoxData.box_id,
                                        group_id: null
                                    },
                                    box_id: resourseBoxData.box_id,
                                    group: {
                                        id: null
                                    },
                                    group_id: null,
                                    id: uploadResourceData.id,
                                    resource: {
                                        ...uploadResourceData,
                                        resource_boxId: resourseBoxData.id
                                    },
                                    resource_id: resourseBoxData.resource_id,
                                    resource_boxId: resourseBoxData.id
                                }
                                updateStateOnFileUploadInDeletedGroupBox(obj, box_id, false)
                                fileUploadRefBox.current.value = null
                                setTimeout(() => {
                                    setIsDisabledBox(false)
                                    // success(response.data.message)
                                }, 1100);

                                setLoadingDeletedGroupBox(false)
                            }).catch((e) => {
                                fileUploadRefBox.current.value = null
                                error(e.response.data.message)
                                setTimeout(() => {
                                    setIsDisabledBox(false)
                                }, 1000);
                                setLoadingDeletedGroupBox(false)
                            })
                        }).catch((e) => {
                            fileUploadRefBox.current.value = null
                            error("File is too large")
                            setTimeout(() => {
                                setIsDisabledBox(false)
                            }, 1000);
                            setLoadingDeletedGroupBox(false)
                        })
                    })
                }
                catch (e) {
                    fileUploadRefBox.current.value = null
                    setTimeout(() => {
                        setLoadingDeletedGroupBox(false)
                        setIsDisabledBox(false)
                    }, 1000);
                }
            }
        }
    }

    const updateStateOnFileUpload = (obj, group_id, box_id, bool) => {
        setShowSelectedResource(null)
        let array = []
        let temp = [...resourceBoxes]
        temp.unshift(obj)
        // if (sortingOrder === 'desc') {
        //     temp.unshift(obj)
        // } else {
        //     temp.push(obj)
        // }
        if (bool) {
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].group_id == group_id && temp[i].box == null) {
                    array.push(temp[i].resource)
                }
            }
            setResourceBoxes(temp)
            // setShowSelectedResource(array)
            setShowSelectedResource(null)
        }
        else {
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].group_id == group_id && temp[i].box !== null && temp[i].box_id == box_id) {
                    array.push(temp[i].resource)
                }
            }
            setResourceBoxes(temp)
            setShowSelectedResource(array)
        }
    }

    const updateStateOnFileUploadInDeletedGroupBox = (obj, box_id, bool) => {
        let temp = [...deletedGroupData]
        let index = temp.findIndex((val) => val.id == box_id)
        temp[index].resources.unshift(obj.resource)
        // if (sortingOrder === 'desc') {
        //     temp[index].resources.unshift(obj.resource)
        // } else {
        //     temp[index].resources.push(obj.resource)
        // }
        setDeletedGroupData(temp)
        setShowSelectedResource(temp[index].resources)
    }

    const handleSelectedResources = (group_id, box_id, bool) => {
        let array = []
        // for group everytime
        if (bool) {
            for (let i = 0; i < resourceBoxes.length; i++) {
                if (resourceBoxes[i].group_id == group_id && resourceBoxes[i].box == null) {
                    array.push(resourceBoxes[i].resource)
                }
            }
            // setShowSelectedResource(array)
            setShowSelectedResource(null)
        }
        else {
            for (let i = 0; i < resourceBoxes.length; i++) {
                if (resourceBoxes[i].group_id == group_id && resourceBoxes[i].box !== null && resourceBoxes[i].box_id == box_id) {
                    array.push(resourceBoxes[i].resource)
                }
            }
            setShowSelectedResource(array)
        }
    }

    useEffect(() => {
        const user = GetAuthUserLocalStorage()
        setUserCurrentSubscription(user.current_subscription)

        const getEsession = async () => {
            try {
                await GetEssionsListing(userData.id).then(async (res) => {
                    if (res.data.data && res.data.data.length > 0) {
                        setHandleCurrentSession(res.data.data[0].name)
                        setHandleAddSessionPopup(false);
                        let session_id = res.data.data[0].id
                        try {
                            setSession(res.data.data[0])
                            getGroups(session_id)
                            getBoxes(session_id)
                            getDeletedGroupBoxes(session_id)
                        } catch (e) {
                            setIsLoading(false)
                            setIsError(true)
                            error(e.response.data.message)
                        }
                    } else {
                        setHandleAddSessionPopup(true)
                        setIsLoading(false)
                        setIsError(true)
                    }
                })
            } catch (e) {
                setIsLoading(false)
                setIsError(true)
                error(e.response.data.message)
            }
        }

        const getGroups = async (session_id) => {
            setIsDisabledBox(true)
            let sortBy = sortingOrder
            try {
                const res = await GetGroups(session_id, sortBy);
                setGroups(res.data.data)
                setIsDisabledBox(false)
            }
            catch (e) {
                setIsDisabledBox(false)
                error(e.response.data.message)
            }
        }

        const getBoxes = async (session_id) => {
            let sortBy = sortingOrder
            try {
                const res = await GetBoxes(session_id, sortBy);
                setBoxes(res.data.data)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        const getResourceBoxes = async () => {
            let array = [];
            // let sortBy = sortingOrder
            try {
                const res = await GetResourceBoxes();
                let data = res.data.data;

                for (let i = 0; i < data.length; i++) {
                    if(typeof data[i].resource !== 'undefined' && data[i].resource !== null){
                        data[i].resource.resource_boxId = data[i].id;
                    }
                    array.push(data[i]);
                }

                setResourceBoxes(array);
            }
            catch (e) {
                error(e.response.data.message);
            }
        }

        const getDeletedGroupBoxes = async (session_id) => {
            try {
                const res = await GetDeletedGroupBoxes(session_id)
                let data = res.data.data
                for (let i = 0; i < data.length; i++) {
                    for (let j = 0; j < data[i].resources.length; j++) {
                        let index = data[i].box_resources.findIndex((val) => val.resource_id == data[i].resources[j].id)
                        data[i].resources[j].resource_boxId = data[i].box_resources[index].id
                    }
                }
                setDeletedGroupData(data)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        getEsession()
        getResourceBoxes()

    }, [userData, sessionCreated, sortingOrder])

    useEffect(() => {
        let array = []
        if (boxes && groups) {
            let temp = [...groups]
            for (let i = 0; i < temp.length; i++) {
                for (let j = 0; j < boxes.length; j++) {
                    if (temp[i].id === boxes[j].group_id) {
                        array.push(boxes[j])
                    }
                }
                temp[i].boxes = array
                array = []
            }
            setMergeGroupAndBoxes(temp)
        }
    }, [groups, boxes])

    useEffect(() => {
        const getDeletedGroupBoxes = async () => {
            try {
                const res = await GetDeletedGroupBoxes(session.id)
                let data = res.data.data
                for (let i = 0; i < data.length; i++) {
                    for (let j = 0; j < data[i].resources.length; j++) {
                        let index = data[i].box_resources.findIndex((val) => val.resource_id == data[i].resources[j].id)
                        data[i].resources[j].resource_boxId = data[i].box_resources[index].id
                    }
                }
                setDeletedGroupData(data)
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        if (count > 0) {
            getDeletedGroupBoxes()
        }
    }, [count])


    useEffect(() => {
        const checkUserSubscription = async () => {
            try {
                const response = await GetCurrentUser()
                if (response.data.data.current_subscription === null) {
                    setTimeout(() => {
                        setUserData(response.data.data)
                        SetAuthUserLocalStorage(response.data.data)
                        navigate(`/sub-admin/subscription`)
                    }, 1500)
                } else {
                    var g1 = new Date();
                    var g2 = new Date(response.data.data.current_subscription.end_at);
                    if (g1.getTime() > g2.getTime()) {
                        setTimeout(() => {
                            setUserData(response.data.data)
                            SetAuthUserLocalStorage(response.data.data)
                            navigate(`/sub-admin/subscription`)
                        }, 1500)
                    }
                }
            }
            catch (e) {
                error(e.response.data.message)
            }
        }

        checkUserSubscription();
    }, [load]);



    // useEffect(() => {
    //     if (loadingBox == true) {
    //         if (now < 97) {
    //             setTimeout(() => {
    //                 setNow(now + 1)
    //             }, ((1 / 1000) * fileSize * (1 / 100)))
    //         }
    //     }
    //     else if (loadingDeletedGroupBox == true) {
    //         if (now2 < 97) {
    //             setTimeout(() => {
    //                 setNow2(now2 + 1)
    //             }, ((1 / 1000) * fileSize * (1 / 100)))
    //         }
    //     }
    //     else {
    //         setNow(0)
    //         setNow2(0)
    //     }
    // }, [loadingBox, now, now2, loadingDeletedGroupBox])

    useEffect(() => {
        const width = window.innerWidth
        if (width < 992) {
            setTimeout(() => {
                setPlacement("bottom")
            }, 1500)
        }
    }, [window.innerWidth])

    const renderTooltip = (message) => (
        <Tooltip style={{ fontSize: "12px" }}>{message}</Tooltip>
    );

    // const setGroupsSortingOrder = (data) => {
    //     setSelectedGroup({
    //         index: "",
    //         id: "",
    //     })
    //     setSelectedBox({
    //         index: "",
    //         id: "",
    //         name: "",
    //         group_index: "",
    //         group_id: ""
    //     })
    //     setShowSelectedResource(null)
    //     setSortingOrder(data)
    // }

    // ************* DRAG AND DROP GROUP **********************
    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging ? "#ff574c" : "transparent",
        ...draggableStyle
    });

    const reorderGroups = (array, startIndex, endIndex) => {
        const result = array;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEndGroup = async (result) => {
        if (!result.destination) {
            return;
        }
        const updatedMergeGroupAndBoxes = reorderGroups(mergeGroupAndBoxes, result?.source?.index, result?.destination?.index);
        updatedMergeGroupAndBoxes.map((group, i) => editGroupOrder(group.id, i + 1))
        setMergeGroupAndBoxes(updatedMergeGroupAndBoxes)
    }

    const editGroupOrder = async (id, position) => {
        setIsDropDisabled(true)
        try {
            await EditGroup(id, null, position)
            setIsDropDisabled(false)
        }
        catch (e) {
            console.log(e.response.data.message)
            setTimeout(async () => {
                setIsDropDisabled(false)
            }, 1500)
        }
    }

    // ************* DRAG AND DROP GROUP **********************


    // ************* DRAG AND DROP BOX **********************

    const reorderBoxes = (array, startIndex, endIndex) => {
        const result = array;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEndBox = async (result, group, i) => {
        if (!result.destination) {
            return;
        }
        console.log('onDragEndBox', result, group, i)
        const updatedBoxes = reorderBoxes(group.boxes, result?.source?.index, result?.destination?.index);
        console.log('updatedBoxes', updatedBoxes)
        updatedBoxes.map((box, i) => editBoxesOrder(box.id, i + 1))
        // setMergeGroupAndBoxes(updatedMergeGroupAndBoxes)
    }

    const editBoxesOrder = async (id, position) => {
        setIsDropDisabled(true)
        try {
            await EditBox(id, null, position)
            setIsDropDisabled(false)
        }
        catch (e) {
            console.log(e.response.data.message)
            setTimeout(async () => {
                setIsDropDisabled(false)
            }, 1500)
        }
    }

    // ************* DRAG AND DROP BOX **********************

    const openAddGroupModal = () => {
        setHandleAddGroupPopup(true);
        if (props.stepsEnabled) {
            props.onCloseIntro();
            props.setShouldIntroReopen(true);
        }
    }

    useEffect(() => {
        if (props.triggerStepAction === 1) {
            setAccordionActiveKey(0);
        }
        if (props.triggerStepAction === 2) {
            setAccordionActiveKey(0);
            if (!mergeGroupAndBoxes.length) return;
            const firstGroup = mergeGroupAndBoxes[0];
            const firstBox = firstGroup.boxes[0];
            if (!firstBox) return;
            setSelectedBox({
                index: firstBox.index,
                id: firstBox.id,
                name: firstBox.name,
                group_index: firstBox.group_index,
                group_id: firstBox.group_id
            })
        }
    }, [props.triggerStepAction]);

    useEffect(() => {
        const openHelpBasedOnCond = () => {
            if (props.lastSavedStep === 1) {
                props.setIntroFlags({...props.introFlags, hasGroup: true});
                props.onHelp(2);
                return;
            }
            if (props.lastSavedStep === 2) {
                props.setIntroFlags({...props.introFlags, hasBox: true});
                props.onHelp(3);
                return;
            }
        }
        if (props.shouldIntroReopen) {
            props.setShouldIntroReopen(false);
            openHelpBasedOnCond();
        }
    }, [mergeGroupAndBoxes, mergeGroupAndBoxes.boxes]);


    return (
        <div className='manage-group-page'>
            <AddGroupPopup session={session} isStateUpdate={true} mergeGroupAndBoxes={mergeGroupAndBoxes} setMergeGroupAndBoxes={setMergeGroupAndBoxes} sortingOrder={sortingOrder} />
            <AddSessionPopup sessionCreated={sessionCreated} setSessionCreated={(value) => {
                setSessionCreated(value);
                if (props.shouldIntroReopen) {
                    props.setShouldIntroReopen(false);
                    props.onHelp();
                }
            }} isStateUpdate={handleAddSessionPopup} />
            <EditGroupPopup selectedGroup={selectedGroup} mergeGroupAndBoxes={mergeGroupAndBoxes} setMergeGroupAndBoxes={setMergeGroupAndBoxes} />
            <EditBoxPopup selectedBox={selectedBox} mergeGroupAndBoxes={mergeGroupAndBoxes} setMergeGroupAndBoxes={setMergeGroupAndBoxes} />
            <EditDeletedBoxPopup selectedBox={selectedBox} deletedGroupData={deletedGroupData} setDeletedGroupData={setDeletedGroupData} />
            <RemoveDeletedBoxPopup setShowSelectedResource={setShowSelectedResource} selectedBox={selectedBox} deletedGroupData={deletedGroupData} setDeletedGroupData={setDeletedGroupData} />
            <MoveDeletedBoxPopup selectedBox={selectedBox} mergeGroupAndBoxes={mergeGroupAndBoxes} handleMoveDeletedBoxPopup={handleMoveDeletedBoxPopup} setHandleMoveDeletedBoxPopup={setHandleMoveDeletedBoxPopup} />

            <AddBoxPopup setMergeGroupAndBoxes={setMergeGroupAndBoxes} mergeGroupAndBoxes={mergeGroupAndBoxes} selectedGroup={selectedGroup} sortingOrder={sortingOrder} />
            <ImagePopup data={imagePopupItem} />
            <PdfPopup data={pdfPopupItem} />
            <AudioPopup data={audioPopupItem} />
            <VideoPopup data={videoPopupItem} />
            <RemoveResourcePopup deletedGroupData={deletedGroupData} setDeletedGroupData={setDeletedGroupData} activeDeletedGroupBox={activeDeletedGroupBox} resourceBoxes={resourceBoxes} setResourceBoxes={setResourceBoxes} showSelectedResource={showSelectedResource} setShowSelectedResource={setShowSelectedResource} selectedResource={selectedResource} />
            <RemoveGroupPopup redirectStatus={false} count={count} setCount={setCount} deletedGroupData={deletedGroupData} setDeletedGroupData={setDeletedGroupData} setShowSelectedResource={setShowSelectedResource} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} mergeGroupAndBoxes={mergeGroupAndBoxes} setMergeGroupAndBoxes={setMergeGroupAndBoxes} />
            <RemoveBoxPopup setShowSelectedResource={setShowSelectedResource} selectedBox={selectedBox} setSelectedBox={setSelectedBox} mergeGroupAndBoxes={mergeGroupAndBoxes} setMergeGroupAndBoxes={setMergeGroupAndBoxes} />
            <Row>
                <Col lg={6} xl={3} className="mb-4">
                    <div className="left p-3">
                        {/* <OverlayTrigger trigger={['hover', 'focus']} placement={placement ? placement : "right"} overlay={renderTooltip(TOOL_TIP_MESSAGES.CREATE_GROUP)}> */}
                        <div id="add-group-btn" className="box w-100 px-3 py-2 mb-2">
                            <span className="custom-tooltip" tooltip-msg={TOOL_TIP_MESSAGES.CREATE_GROUP} ><BsFillInfoCircleFill /></span>
                            <div className='d-flex align-items-center' onClick={() => openAddGroupModal()}>
                                <div className='icon-container'>
                                    <AddGroupIconMedium />
                                </div>
                                <h6 className='m-0 p-0 ms-3'>Add New Group</h6>
                            </div>
                        </div>
                        {/* </OverlayTrigger> */}

                        <div className="holder">
                            <div className="accordian-container">
                                <div className="mt-2">
                                    {/* {
                                        sortingOrder === 'asc' ?
                                            <span onClick={!isDisabledBox ? () => setGroupsSortingOrder('desc') : undefined}>
                                                <RiSortDesc className='me-2 icon text-grey' />
                                            </span>
                                            :
                                            <span onClick={!isDisabledBox ? () => setGroupsSortingOrder('asc') : undefined}>
                                                <RiSortAsc className='me-2 icon text-grey' />
                                            </span>
                                    } */}
                                </div>
                                {/* ******* DRAG AND DROP ******** */}
                                <DragDropContext onDragEnd={onDragEndGroup}  >
                                    <Droppable droppableId="droppable" isDropDisabled={isDropDisabled}>
                                        {(provided, snapshot) => (
                                            <Accordion
                                                key={accordionActiveKey}
                                                defaultActiveKey={accordionActiveKey}
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {
                                                    mergeGroupAndBoxes.length > 0 &&
                                                    mergeGroupAndBoxes.map((group, i) => (

                                                        <Accordion.Item key={i} className="border-bottom" eventKey={i}>
                                                            <Draggable key={group?.id.toString()} draggableId={group?.id.toString()} index={i} >
                                                                {(provided, snapshot) => (
                                                                    <Accordion.Header
                                                                        className="py-4"
                                                                        eventKey={i}
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style
                                                                        )}

                                                                        aria-disabled={isDisabledBox}
                                                                        onClick={!isDisabledBox ? () => {
                                                                            setShowSelectedResource(null)
                                                                            setSelectedGroup({
                                                                                id: group.id,
                                                                                index: i,
                                                                                name: group.name
                                                                            })
                                                                            setSelectedBox({
                                                                                id: "",
                                                                                index: "",
                                                                                name: "",
                                                                                group_id: "",
                                                                                group_index: ""
                                                                            })
                                                                            setActiveDeletedGroupBox(false)
                                                                            handleSelectedResources(group.id, null, true)
                                                                        } : undefined}>
                                                                        <span className="title-group">{group.name}</span>
                                                                        <span className="delete-btn-container" onClick={() => {
                                                                            setHandleRemoveGroupPopup(true)
                                                                            setActiveDeletedGroupBox(false)
                                                                            setSelectedGroup({
                                                                                id: group.id,
                                                                                index: i,
                                                                                name: group.name
                                                                            })
                                                                        }}>
                                                                            <RiDeleteBin6Line className='me-2 icon text-grey' />
                                                                        </span>

                                                                        <span className="edit-btn-container" onClick={() => {
                                                                            setSelectedGroup({
                                                                                id: group.id,
                                                                                index: i,
                                                                                name: group.name
                                                                            })
                                                                            setActiveDeletedGroupBox(false)
                                                                            setHandleEditGroupPopup(true)
                                                                        }}>
                                                                            <RiEdit2Line className='me-2 icon text-grey' />
                                                                        </span>
                                                                    </Accordion.Header>
                                                                )}
                                                            </Draggable>
                                                            <Accordion.Body>
                                                                {/* <OverlayTrigger trigger={['hover', 'focus']} placement={placement ? placement : "right"} overlay={renderTooltip(TOOL_TIP_MESSAGES.CREATE_BOX)}> */}
                                                                <div className="box-small w-100 p-2 mb-3"
                                                                     id={i === 0 ? 'first-accordion-item-btn' : null}
                                                                     onClick={!isDisabledBox ? () => {
                                                                         if (props.stepsEnabled) {
                                                                             props.onCloseIntro();
                                                                             props.setShouldIntroReopen(true);
                                                                         }
                                                                         setActiveDeletedGroupBox(false)
                                                                         setHandleAddBoxPopup(true)
                                                                         setSelectedGroup({
                                                                             id: group.id,
                                                                             index: i,
                                                                             name: group.name
                                                                         })
                                                                     } : undefined}
                                                                >
                                                                    <span className="text-orange custom-tooltip"
                                                                          tooltip-msg={TOOL_TIP_MESSAGES.CREATE_BOX}><BsFillInfoCircleFill/></span>
                                                                    <div
                                                                        aria-disabled={isDisabledBox}
                                                                        onClick={!isDisabledBox ? () => {
                                                                            setActiveDeletedGroupBox(false)
                                                                            setHandleAddBoxPopup(true)
                                                                            setSelectedGroup({
                                                                                id: group.id,
                                                                                index: i,
                                                                                name: group.name
                                                                            })
                                                                        } : undefined}
                                                                        className='d-flex align-items-center'
                                                                    >
                                                                        <div className='icon-container'>
                                                                            <AddGroupIconSmall/>
                                                                        </div>
                                                                        <h6 className='mb-0 p-0 ms-3'>+ Add New Box</h6>
                                                                    </div>
                                                                </div>
                                                                {/* </OverlayTrigger> */}

                                                                <DragDropContext onDragEnd={(result) => onDragEndBox(result, group, i)}>
                                                                    <Droppable droppableId="droppable-box" isDropDisabled={isDropDisabled}>
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                {...provided.droppableProps}
                                                                                ref={provided.innerRef}
                                                                            >
                                                                                {
                                                                                    group.boxes.length > 0 &&
                                                                                    group.boxes.map((box, j) => (
                                                                                        <Draggable key={box?.id.toString()} draggableId={box?.id.toString()} index={j} >
                                                                                            {(provided, snapshot) => (
                                                                                                <div
                                                                                                    ref={provided.innerRef}
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps}
                                                                                                    style={getItemStyle(
                                                                                                        snapshot.isDragging,
                                                                                                        provided.draggableProps.style
                                                                                                    )}

                                                                                                    key={j}
                                                                                                    className='sub-description cutbox d-flex align-items-center justify-content-between'
                                                                                                    aria-disabled={isDisabledBox}
                                                                                                    onClick={!isDisabledBox ? () => {
                                                                                                        setShowSelectedResource(null)
                                                                                                        setSelectedGroup({
                                                                                                            id: "",
                                                                                                            index: "",
                                                                                                            name: ""
                                                                                                        })

                                                                                                        setSelectedBox({
                                                                                                            id: box.id,
                                                                                                            index: j,
                                                                                                            name: box.name,
                                                                                                            group_id: box.group_id,
                                                                                                            group_index: i
                                                                                                        })

                                                                                                        setActiveDeletedGroupBox(false)
                                                                                                        handleSelectedResources(box.group_id, box.id, false)
                                                                                                    } : undefined}
                                                                                                >
                                                                                                    <h6 className={`py-2 m-0`}>
                                                                                                        <span style={{ cursor: "pointer" }}>{box.name}</span>
                                                                                                    </h6>

                                                                                                    <div className="edit-delete-wrapper">
                                                                                                        <span className="me-1" onClick={() => {
                                                                                                            setSelectedGroup({
                                                                                                                id: "",
                                                                                                                index: "",
                                                                                                                name: ""
                                                                                                            })

                                                                                                            setSelectedBox({
                                                                                                                id: box.id,
                                                                                                                index: j,
                                                                                                                name: box.name,
                                                                                                                group_id: box.group_id,
                                                                                                                group_index: i
                                                                                                            })

                                                                                                            setActiveDeletedGroupBox(false)
                                                                                                            setHandleEditBoxPopup(true)
                                                                                                        }}>
                                                                                                            <RiEdit2Line className='me-1 icon text-grey' />
                                                                                                        </span>

                                                                                                        <span className="" onClick={() => {
                                                                                                            setActiveDeletedGroupBox(false)
                                                                                                            setHandleRemoveBoxPopup(true)
                                                                                                        }}>
                                                                                                            <RiDeleteBin6Line className='me-2 icon text-grey' />
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Droppable>
                                                                </DragDropContext >

                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    ))
                                                }
                                            </Accordion>
                                        )}
                                    </Droppable>
                                </DragDropContext >
                                {/* ******* DRAG AND DROP ******** */}

                                {/* {
                                    <Accordion defaultActiveKey="0">
                                        {
                                            mergeGroupAndBoxes.length > 0 &&
                                            mergeGroupAndBoxes.map((group, i) => (
                                                <Accordion.Item key={i} className="border-bottom" eventKey={i}>
                                                    <Accordion.Header
                                                        aria-disabled={isDisabledBox}
                                                        onClick={!isDisabledBox ? () => {
                                                            setSelectedGroup({
                                                                id: group.id,
                                                                index: i,
                                                                name: group.name
                                                            })

                                                            setSelectedBox({
                                                                id: "",
                                                                index: "",
                                                                name: "",
                                                                group_id: "",
                                                                group_index: ""
                                                            })

                                                            setActiveDeletedGroupBox(false)

                                                            handleSelectedResources(group.id, null, true)
                                                        } : undefined}>
                                                        <span className="title-group">{group.name}</span>
                                                        <span className="delete-btn-container" onClick={() => {
                                                            setHandleRemoveGroupPopup(true)
                                                            setActiveDeletedGroupBox(false)
                                                            setSelectedGroup({
                                                                id: group.id,
                                                                index: i,
                                                                name: group.name
                                                            })
                                                        }}>
                                                            <RiDeleteBin6Line className='me-2 icon text-grey' />
                                                        </span>

                                                        <span className="edit-btn-container" onClick={() => {
                                                            setSelectedGroup({
                                                                id: group.id,
                                                                index: i,
                                                                name: group.name
                                                            })
                                                            setActiveDeletedGroupBox(false)
                                                            setHandleEditGroupPopup(true)
                                                        }}>
                                                            <RiEdit2Line className='me-2 icon text-grey' />
                                                        </span>
                                                    </Accordion.Header>

                                                    <Accordion.Body>
                                                        <OverlayTrigger trigger={['hover', 'focus']} placement={placement ? placement : "right"} overlay={renderTooltip(TOOL_TIP_MESSAGES.CREATE_BOX)}>
                                                            <div className="box-small w-100 p-2 d-flex align-items-center"
                                                                aria-disabled={isDisabledBox}
                                                                onClick={!isDisabledBox ? () => {
                                                                    setActiveDeletedGroupBox(false)
                                                                    setHandleAddBoxPopup(true)
                                                                    setSelectedGroup({
                                                                        id: group.id,
                                                                        index: i,
                                                                        name: group.name
                                                                    })
                                                                } : undefined}>
                                                                <div className='icon-container'>
                                                                    <AddGroupIconSmall />
                                                                </div>
                                                                <h6 className='m-0 p-0 ms-3'>+ Add New Box</h6>
                                                            </div>
                                                        </OverlayTrigger>

                                                        {
                                                            group.boxes.length > 0 &&
                                                            group.boxes.map((box, j) => (
                                                                <div key={j} className='sub-description mt-3 d-flex align-items-center justify-content-between'
                                                                    aria-disabled={isDisabledBox}
                                                                    onClick={!isDisabledBox ? () => {
                                                                        setSelectedGroup({
                                                                            id: "",
                                                                            index: "",
                                                                            name: ""
                                                                        })

                                                                        setSelectedBox({
                                                                            id: box.id,
                                                                            index: j,
                                                                            name: box.name,
                                                                            group_id: box.group_id,
                                                                            group_index: i
                                                                        })

                                                                        setActiveDeletedGroupBox(false)
                                                                        handleSelectedResources(box.group_id, box.id, false)
                                                                    } : undefined}
                                                                >
                                                                    <h6 className={`m-0 p-0`}>
                                                                        {box.name}
                                                                    </h6>

                                                                    <div>
                                                                        <span className="me-1" onClick={() => {
                                                                            setSelectedGroup({
                                                                                id: "",
                                                                                index: "",
                                                                                name: ""
                                                                            })

                                                                            setSelectedBox({
                                                                                id: box.id,
                                                                                index: j,
                                                                                name: box.name,
                                                                                group_id: box.group_id,
                                                                                group_index: i
                                                                            })

                                                                            setActiveDeletedGroupBox(false)
                                                                            setHandleEditBoxPopup(true)
                                                                        }}>
                                                                            <RiEdit2Line className='me-1 icon text-grey' />
                                                                        </span>

                                                                        <span className="" onClick={() => {
                                                                            setActiveDeletedGroupBox(false)
                                                                            setHandleRemoveBoxPopup(true)
                                                                        }}>
                                                                            <RiDeleteBin6Line className='me-2 icon text-grey' />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))
                                        }
                                    </Accordion>
                                } */}
                                <div className='mt-3'>
                                    {
                                        deletedGroupData.length > 0 &&
                                        deletedGroupData.map((box, i) => {
                                            return (
                                                <div key={i} className="deleted-group-data d-flex align-items-center mb-2 justify-content-between">
                                                    <h6
                                                        className="m-0 title"
                                                        onClick={() => {
                                                            setSelectedBox({
                                                                id: box.id,
                                                                index: i,
                                                                name: box.name,
                                                                group_id: null,
                                                                group_index: null
                                                            })
                                                            setActiveDeletedGroupBox(true)
                                                            setShowSelectedResource(box?.resources)
                                                        }}
                                                    >
                                                        {box.name}
                                                    </h6>
                                                    <div>
                                                        {/*<span*/}
                                                        {/*    className='icon text-grey me-2'*/}
                                                        {/*    onClick={() => {*/}
                                                        {/*        setSelectedBox({*/}
                                                        {/*            id: box.id,*/}
                                                        {/*            index: i,*/}
                                                        {/*            name: box.name,*/}
                                                        {/*            group_id: null,*/}
                                                        {/*            group_index: null*/}
                                                        {/*        })*/}

                                                        {/*        setHandleMoveDeletedBoxPopup(true)*/}
                                                        {/*    }}*/}
                                                        {/*>*/}
                                                        {/*    <MdDriveFileMoveOutline />*/}
                                                        {/*</span>*/}

                                                        <span
                                                            className='icon text-grey me-2'
                                                            onClick={() => {
                                                                setSelectedBox({
                                                                    id: box.id,
                                                                    index: i,
                                                                    name: box.name,
                                                                    group_id: null,
                                                                    group_index: null
                                                                })

                                                                setHandleEditDeletedGroupPopup(true)
                                                            }}
                                                        >
                                                            <RiEdit2Line />
                                                        </span>

                                                        <span
                                                            className='icon text-grey'
                                                            onClick={() => {
                                                                setSelectedBox({
                                                                    id: box.id,
                                                                    index: i,
                                                                    name: box.name,
                                                                    group_id: null,
                                                                    group_index: null
                                                                })

                                                                setHandleRemoveDeletedGroupPopup(true)
                                                            }}
                                                        >
                                                            <RiDeleteBin6Line />
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col lg={6} xl={9} className="mb-4">
                    <div className="right p-3">
                        <div className="holder">
                            <Row className={`${showSelectedResource === null || showSelectedResource.length <= 0 ? "h-100" : ""} `}>
                                <Col xs={12} xl={12} className={`${showSelectedResource === null || showSelectedResource.length <= 0 ? "h-100" : ""} `}>
                                    <Row className="mb-4">
                                        <Col xl={{ span: 6, offset: 6 }}>
                                            {
                                                selectedBox.id !== "" &&
                                                <>
                                                    {
                                                        activeDeletedGroupBox ?
                                                            // <OverlayTrigger trigger={['hover', 'focus']} placement={placement ? placement : "left"} overlay={renderTooltip(TOOL_TIP_MESSAGES.CREATE_RESOURCE)}>
                                                            <div className="box w-100 p-3">
                                                                <span className="custom-tooltip" tooltip-msg={TOOL_TIP_MESSAGES.CREATE_RESOURCE}><BsFillInfoCircleFill /></span>

                                                                <label className='d-block' htmlFor="myfile">
                                                                    <input
                                                                        disabled={isDisabledBox}
                                                                        accept='.jpg, .jpeg, .png, .jfif, .tiff, .mp4, .mov, .mp3, .pdf, .docx, .txt, .xlsx, .m4v'
                                                                        ref={fileUploadRefBox}
                                                                        onClick={() => {
                                                                            setSelectedGroup({
                                                                                id: "",
                                                                                index: "",
                                                                                name: ""
                                                                            })
                                                                        }}
                                                                        onChange={(e) => {
                                                                            handleFileUploadInBox(e, selectedBox.id, selectedBox.group_id).then(() => {
                                                                                props.setIntroFlags({...props.introFlags, hasBoxResource: true});
                                                                                if (props.shouldIntroReopen) {
                                                                                    props.setShouldIntroReopen(false);
                                                                                    props.onHelp(4);
                                                                                }
                                                                            })
                                                                        }}
                                                                        className='d-none' type="file" name="file" id="myfile"
                                                                    />
                                                                    <div className="d-flex align-items-center">
                                                                        <div className='icon-container'>
                                                                            <AddGroupIconMedium />
                                                                        </div>
                                                                        {
                                                                            loadingDeletedGroupBox ?
                                                                                <div className='mt-20 d-flex align-items-center flex-column w-100'>
                                                                                    <ProgressBar className='w-100' now={now2} />
                                                                                    <p className='m-0 fw-500 w-100 text-orange text-end'>{now2 + "%"}</p>
                                                                                    <small>larger files may simply take longer to upload. Please be patient. 1GB max file size</small>
                                                                                </div>
                                                                                :
                                                                                <h6 className='m-0 p-0 ms-3'>Add New Resource In {selectedBox.name}</h6>
                                                                        }
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            // </OverlayTrigger>
                                                            :

                                                            //  <OverlayTrigger trigger={['hover', 'focus']} placement={placement ? placement : "left"} overlay={renderTooltip(TOOL_TIP_MESSAGES.CREATE_RESOURCE)}>
                                                            <div id="add-content-box" className="box w-100 p-3">
                                                                <span className="custom-tooltip" tooltip-msg={TOOL_TIP_MESSAGES.CREATE_RESOURCE}><BsFillInfoCircleFill /></span>

                                                                <label className='d-block' htmlFor="myfile">
                                                                    <input
                                                                        disabled={isDisabledBox}
                                                                        accept='.jpg, .jpeg, .png, .jfif, .tiff, .mp4, .mov, .mp3, .pdf, .docx, .txt, .xlsx, .m4v'
                                                                        ref={fileUploadRefBox}
                                                                        onClick={() => {
                                                                            if (props.stepsEnabled) {
                                                                                props.onCloseIntro();
                                                                                props.setShouldIntroReopen(true);
                                                                            }
                                                                            setSelectedGroup({
                                                                                id: "",
                                                                                index: "",
                                                                                name: ""
                                                                            })
                                                                        }}
                                                                        onChange={(e) => {
                                                                            handleFileUploadInBox(e, selectedBox.id, selectedBox.group_id).then(() => {
                                                                                props.setIntroFlags({...props.introFlags, hasBoxResource: true});
                                                                                if (props.shouldIntroReopen) {
                                                                                    props.setShouldIntroReopen(false);
                                                                                    props.onHelp(4);
                                                                                }
                                                                            })
                                                                        }}
                                                                        className='d-none' type="file" name="file" id="myfile"
                                                                    />
                                                                    <div className='d-flex align-items-center'>
                                                                        <div className='icon-container'>
                                                                            <AddGroupIconMedium />
                                                                        </div>
                                                                        {
                                                                            loadingBox ?
                                                                                <div className='mt-20 d-flex align-items-center flex-column w-100'>
                                                                                    <ProgressBar className='w-100' now={now} />
                                                                                    <p className='m-0 fw-500 w-100 text-orange text-end'>{now + "%"}</p>
                                                                                    <small>larger files may simply take longer to upload. Please be patient. 1GB max file size</small>
                                                                                </div>
                                                                                :
                                                                                <h6 className='m-0 p-0 ms-3'>Add New Resource In {selectedBox.name}</h6>
                                                                        }
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        //  </OverlayTrigger>
                                                    }
                                                </>

                                            }

                                        </Col>
                                    </Row>
                                    <Row className={`${showSelectedResource === null ? "h-100" : ""} `}>
                                        {
                                            showSelectedResource === null ?
                                                <Col className="d-flex align-items-center mt-2 text-center justify-content-center h-100 my-auto" xs={12}>
                                                    <div>
                                                        <h3 className='no-items'>Please select box to see available resources</h3>
                                                    </div>
                                                </Col>
                                                :
                                                showSelectedResource.length > 0 ?
                                                    showSelectedResource.map((v, i) => (
                                                        <Col key={i} xl={6} className="mb-4">
                                                            {
                                                                returnCardInformation(v, i)
                                                            }
                                                        </Col>
                                                    ))
                                                    :
                                                    <div className="mt-5">
                                                        <h3 className='text-center no-items'>No resources available</h3>
                                                    </div>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col >
            </Row >
        </div >
    )
}

export default ManageGroupPage


