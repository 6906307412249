import React, { useState, useContext, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { AddEmailTemplate } from '../constants/svgs'
import parse from 'html-react-parser';
import { GetEmailTemplate } from '../services/auth/auth.services';
import { success, error } from "../constants/msg";
import { UserDataContext } from '../App';

const EmailTemplatePage = () => {
    const [userData, setUserData] = useContext(UserDataContext);
    const navigate = useNavigate()
    const [emailTemplate, setEmailTemplate] = useState()

    useEffect(() => {
        const getEmailTemplate = async () => {
            try {
                const res = await GetEmailTemplate(userData.id);
                let description = res.data.data[0].html_body
                setEmailTemplate(description)
            }
            catch (e) {
                error(e.response.data.errors[0].message)
            }
        }
        getEmailTemplate()
    }, [])

    return (
        <div className='email-template-page'>
            <Row>
                <Col md={6} xl={7} className="mb-4">
                    <div className='left p-4'>
                        <div className="holder">
                            <h5 className='m-0 p-0 border-bottom w-100 pb-4 mb-4'>Email Template</h5>
                            {
                                emailTemplate &&
                                    parse(emailTemplate) 
                                    // <span className="text-grey">No Email Template Available</span>
                            }
                        </div>
                    </div>
                </Col>

                <Col md={6} xl={{ span: 4, offset: 1 }} className="mb-4">
                    <div id="edit-email-template" className="right p-4">
                        <h5>{emailTemplate ? "Edit" : "Add New"} Email Template</h5>
                        <div className="holder mt-3 p-4" onClick={() => navigate("/sub-admin/email-setup", { state: { edit: true } })}>
                            <div className="border-dotted d-flex align-items-center justify-content-center py-5 p-4">
                                <div className="img-container">
                                    <AddEmailTemplate />
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default EmailTemplatePage