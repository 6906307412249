import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import Assets from '../../constants/images';
import { useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";

import { useNavigate, NavLink } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { ForgetPasswordRequest, GetAuthUserLocalStorage, Login, Signup, StorageDeviceToken } from "../../services/auth/auth.services"
import { roles, constant } from "../../utils/constants"
import { SetTokenLocalStorage, SetAuthUserLocalStorage } from "../../services/auth/auth.services"
import { UserDataContext } from "../../App"

const SignupForm = ({ btnText }) => {
    const navigate = useNavigate();
    const [userData, setUserData] = useContext(UserDataContext);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [deviceToken, setDeviceToken] = useState(null);

    const [isDisabled, setIsDisabled] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        const getStorageDeviceToken = async () => {
            const res = await StorageDeviceToken();
            setDeviceToken(JSON.parse(res))
        }
        getStorageDeviceToken()
    }, [])

    const onSubmit = async (data) => {
        const { companyName, email, password } = data;
        const confirmPassword = password
        const name = companyName
        try {
            setIsDisabled(true)
            const res = await Signup(companyName, name, email, password, confirmPassword, roles.ROLE_SUB_ADMIN, deviceToken ? deviceToken.device_type : constant.DEVICE_TYPE, deviceToken ? deviceToken.device_token : constant.DEVICE_TOKEN)
            setTimeout(() => { success(res.data.message) }, 2000)
            setTimeout(() => {
                setIsDisabled(false)
                navigate("/signin")
            }, 1500)
        }
        catch (e) {
            error(e.response.data.message)
            setTimeout(() => {
                setIsDisabled(false)
            }, 1500)
        }
    }

    return (
        <div className='forms signup-form p-5 py-xl-4 py-xxl-5 mb-4'>

            <div className='text-center border-bottom pb-4'>
                <img id="logo" className='img-fluid' src={Assets.Logo} alt="Logo" />
            </div>

            <div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='mt-4'>
                        <Form.Label>Organization Name *</Form.Label>
                        <InputGroup className=''>
                            <Form.Control
                                onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) && e.preventDefault()) && e.preventDefault()}
                                name="companyName"
                                type="text"
                                placeholder="Enter your organization name *"
                                maxLength={VALIDATIONS.NAME}
                                {...register("companyName",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.NAME,
                                            message: VALIDATIONS_TEXT.NAME_MAX
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.ORGANIZATION_NAME_REQUIRED
                                        },
                                    })
                                }
                            />
                        </InputGroup>
                        {errors.name && <small className='text-red'>{errors.name.message}</small>}
                    </div>

                    <div className='mt-4'>
                        <Form.Label>E-Mail Address  *</Form.Label>
                        <InputGroup className=''>
                            <Form.Control
                                name="email"
                                type="email"
                                placeholder="Enter email address *"
                                maxLength={VALIDATIONS.EMAIL}
                                {...register("email",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.EMAIL,
                                            message: VALIDATIONS_TEXT.EMAIL_MAX
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                        },
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                        }
                                    })
                                }
                            />
                        </InputGroup>
                        {errors.email && <small className='text-red'>{errors.email.message}</small>}
                    </div>

                    <div className='mt-4'>
                        <Form.Label>Password  *</Form.Label>
                        <InputGroup>
                            <Form.Control
                                name="password"
                                className="password-input"
                                placeholder="Password *"
                                type={!showPassword ? "password" : "text"}
                                maxLength={VALIDATIONS.PASSWORD_MAX}
                                {...register("password",
                                    {
                                        minLength: {
                                            value: VALIDATIONS.PASSWORD_MIN,
                                            message: VALIDATIONS_TEXT.PASSWORD_MIN
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.PASSWORD_MAX,
                                            message: VALIDATIONS_TEXT.PASSWORD_MAX
                                        },
                                        pattern: {
                                            value: /^.*(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
                                            message: VALIDATIONS_TEXT.INVALID_PASSWORD_FORMAT
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                        },
                                    })
                                }
                            />
                            <InputGroup.Text>
                                {
                                    !showPassword ?
                                        <AiOutlineEye className="eye-icon" onClick={() => setShowPassword(!showPassword)} /> :
                                        <AiOutlineEyeInvisible className="eye-icon" onClick={() => setShowPassword(!showPassword)} />
                                }
                            </InputGroup.Text>
                        </InputGroup>
                        {errors.password && <small className='text-red'>{errors.password.message}</small>}
                    </div>

                    <div className='text-center'>
                        <Button disabled={isDisabled} className="w-100 mt-4 btn-signin" type="submit">{btnText}</Button>
                    </div>

                    <p className='mb-0 mt-4 text-center'>Already have an account ? <NavLink to="/signin">Log In</NavLink></p>
                </Form>
            </div>
        </div>
    )
}

export default SignupForm