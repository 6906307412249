import React, { useEffect } from 'react'
import { useState } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import SubscriptionPlanCard from '../components/home/SubscriptionPlanCard'
import Assets from '../constants/images'
import {
    GetAuthUserLocalStorage,
    GetBoxes, GetDeletedGroupBoxes,
    GetEssionsListing,
    GetGroups,
    GetResourceBoxes,
    GetSubscriptionPackages
} from "../services/auth/auth.services";
import { error } from "../constants/msg";
import { useNavigate } from 'react-router-dom'
import Loader from '../components/loader/Loader'

const SubscriptionUpgradePage = () => {
    const navigate = useNavigate()
    const [subscriptionPackages, setSubscriptionPackages] = useState([]);
    const [loading, setLoading] = useState(true)
    const [userCurrent_subscription, setUserCurrent_subscription] = useState(true)

    useEffect(() => {
        const user = GetAuthUserLocalStorage()
        setUserCurrent_subscription(user.current_subscription)
        const getSubscriptionPackages = async (session_id) => {
            try {
                const res = await GetSubscriptionPackages();
                setSubscriptionPackages(res.data.data)
                setLoading(false)
            } catch (e) {
                error(e.response.data.message)
            }
        }

        getSubscriptionPackages()
    }, [])

    return (
        <Container>
            <div className='subscription-page bg-white p-4'>
                <div className='text-center border-bottom pb-4'>
                    <img className='img-fluid' src={Assets.Logo} alt="Logo" />
                </div>

                <div className='text-center mt-5 mb-4'>
                    <h5>Subscription</h5>
                    <p className='mt-3'>Update your subscription here.</p>
                </div>
                <div>
                    {
                        loading ?
                            <Row>
                                <Col xs={12}>
                                    <div className='w-100 text-center'>
                                        <Loader />
                                    </div>
                                </Col>
                            </Row>
                            :
                            <Row
                                className='justify-content-center justify-content-sm-start justify-content-md-center justify-content-lg-start'>
                                {
                                    subscriptionPackages.map((v, i) => {
                                        return (
                                            <Col key={i} sm={6} md={9} lg={6} xl={4} xxl={4} className="mb-4">
                                                <SubscriptionPlanCard navigateLink="/sub-admin/settings/subscription-upgrade" showBtn={true} data={v}
                                                    permissions={JSON.parse(v?.data)} />
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                    }
                </div>
            </div>
        </Container>
    )
}

export default SubscriptionUpgradePage